<template>
    <transition name="pop">
        <div class="container" v-if="showme">
            <h1>{{$root.translation[$root.language].accueil_title}}</h1>
            <div class="header">
                <div class="left">
                    <button class="firststeps" @click="$root.$router.push('/'+$root.language+'/firststeps')"><h2>{{$root.translation[$root.language].mpp}}</h2><p>{{$root.translation[$root.language].mpp_content}}</p></button>

                    <button class="learn" @click="$root.$router.push('/'+$root.language+'/learn')"><h2>{{$root.translation[$root.language].learn}}</h2><p>{{$root.translation[$root.language].learn_content}}</p></button>

                    <button class="modop" @click="$root.$router.push('/modop')" v-if='$root.language == "fr"'><h2>{{$root.translation[$root.language].modop}}</h2><p>{{$root.translation[$root.language].modop_content}}</p></button>

                </div>
                <div class="right">
                    <!--<video crossorigin playsinline="playsinline" webkit-playsinline="webkit-playsinline" controls>
                            <source src="@/assets/video/learn.mp4" type="video/mp4" @error="catcherrorvideo = true"/>
                        </video>

                        <div v-if="catcherrorvideo">Erreur Video : Désactivez votre ad-blocker</div>-->
                        <!--<VideoPlayer :video_url="require('@/assets/video/learn.mp4')"/>-->
                </div>
            </div>
            <!--<div class="news">
                <h2>Actualités</h2>
                <div class="contents">
                    <div>
                        <h3>Installer son application sur les téléphones android</h3>
                        <iframe width="100%" height="auto" src="https://www.youtube.com/embed/XsxDYPb4t80" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </div>-->
        </div>
    </transition>
</template>
<script>
import { useLearnStore } from '@/stores/learn'
import { kcAuthStore } from '@/stores/kcAuth'
//import VideoPlayer from '@/components/VideoPlayer'

export default {
    name: "GlobalHome",
    data() {
        return {
            dataslearn: useLearnStore(),
            auth: kcAuthStore(),
            catcherrorvideo: false,
            showme: false,
        }
    },
    components: {
      //VideoPlayer: VideoPlayer
    },
    created() {
        let that = this;
        this.$root.showNav = true;
        this.$root.showMenu = false;

        setTimeout(function() {
            that.showme = true;
        }, 100)



    },
    mounted() {
        if (this.lng !== 'fr' && this.lng !== 'es') {
            this.$root.$router.push("/"+this.$root.language+"/"+this.lng);
        } else {
            this.$root.language = this.lng
        }

        this.$root.addPageVisit('global_home');
    },
    watch: {
        lng: function(newVal) {
            if (newVal !== 'fr' && newVal !== 'es') {
                this.$root.$router.push("/"+this.$root.language+"/"+newVal);
            } else {
                this.$root.language = this.lng
            }
        },
    },
    props:['lng']
}
</script>
<style scoped>
    h1 {
        color: var(--clr-blue);
        font-size: var(--fs-700);
        font-weight: 700;
        text-align: left;
        margin-bottom: 1em;
    }
    h2 {
        color: var(--clr-blue);
        font-weight: 700;
        text-align: left;
    }
    h3 {
        color: var(--clr-blue);
        font-weight: 700;
        text-align: left;
    }
    .container {
        display: flex;
        flex-direction: column;
    }

    .mobile .container {
        padding: 6em 1em 1em 1em;
    }

    .header {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 1em;
        margin-bottom: 2em;
    }

    .mobile .header {
        grid-template-columns: 100%;
    }

    .header .left {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }

    .header .left button {
        appearance: none;
        background-color: var(--clr-white);
        border: none;
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-radius: 1em;
        align-content: center;
        align-items: center;
        padding: 2em 1em 2em 4em;
        text-align: left;
        box-shadow: 0px 3px 6px rgba(2, 53, 112, 0.14);
        background-repeat: no-repeat;
        background-position: center left 2em;
        background-size: 1.5em 1.5em;
        cursor: pointer;
        gap: 1em;
    }

    .header .left button.learn {
        background-image: url(@/assets/css/img/icon_learn.svg);
    }

    .header .left button.firststeps {
        background-image: url(@/assets/css/img/icon_firststeps.svg);
    }

    .header .left button.modop {
        background-image: url(@/assets/css/img/icon_modop.svg);
    }

    .header video {
        width: 100%;
        border-radius: 1em;
    }

    .news h2 {
        margin-bottom: 1em;

        font-size: var(--fs-700);
    }

    .news .contents {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1em;
    }

    .mobile .news .contents {
        grid-template-columns: 1fr;
    }

    .news .contents > * {
        background-color: var(--clr-white);
        padding: 1em;
        border-radius: 1em;
        box-shadow: 0px 3px 6px rgba(2, 53, 112, 0.14);
        display: flex;
        flex-direction: column;
        gap: 1em;
    }

    .news .contents iframe {
        width: 100%;
    }

    .pop-enter-active,
    .pop-leave-active {
        transition: all 350ms;
    }

    .pop-enter-from {
      opacity: 0;
      scale: .9;
    }

    .pop-enter-to {
      opacity: 1;
      scale: 1;
    }

    .pop-leave-from {
        display: none;
        opacity: 1;
        scale: 1;
    }

    .pop-leave-to {
        display: none;
        opacity: 0;
        scale: .9;
    }

</style>