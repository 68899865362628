<template>
    <div>
        <h1 v-if="!dataslearn.datas.ready">{{$root.translation[$root.language].loading}}</h1>
        <div v-if="!dataslearn.datas.ready" class="lds-dual-ring"></div>
        <router-view v-if="dataslearn.datas.ready" />
        <!--<router-view v-slot="{ Component, route } " v-if="dataslearn.datas.version">
            <transition name="fade">
                <component :is="Component"  :key="route.path" />
            </transition>
        </router-view>-->
    </div>
</template>
<script>
import { useLearnStore } from '@/stores/learn'
import { kcAuthStore } from '@/stores/kcAuth'

export default {
    name: "LearnContainer",
    data() {
        return {
            datasLoaded: false,
            dataslearn: useLearnStore(),
            auth: kcAuthStore(),
        }
    },
    created() {
        let that = this;

        that.dataslearn.fetchUserTrackings();

        if (this.lng !== 'fr' && this.lng !== 'es') {
            this.$root.$router.push("/"+this.$root.language+"/"+this.lng);
        } else {
            this.$root.language = this.lng
        }

        

    },
    methods: {

   },
    props: ['lng']

}
</script>
<style scoped>
h1 {
    font-size: var(--fs-700);
    font-weight: 700;
    color: var(--clr-blue);
    margin-bottom: 2em;
}

.mobile h1 {
	margin-top: 6em;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--clr-blue);;
  border-color: var(--clr-blue-light) transparent var(--clr-blue) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>