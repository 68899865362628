import { defineStore } from 'pinia'

export const kcAuthStore = defineStore('kcAuth', {
    state: () => {
        return {
            user: JSON.parse(localStorage.getItem('kc_data')) || {},
            apikey: null,
            useragent: undefined,
            saveInProgress: false,
            prod:false
        }
    },
    // could also be defined as
    // state: () => ({ count: 0 })
    getters: {

        isLoggedIn() {
            if (this.user && this.user.token) {

                if (!sessionStorage.getItem("new_session")) {
                    this.setTrack('app', 'open', 'webapp');
                    sessionStorage.setItem("new_session", 1);
                }


                if (sessionStorage.getItem("new_session") == 1 && this.useragent) {
                    this.setTrack('app', 'useragent', this.useragent);
                    sessionStorage.setItem("new_session", 2);
                }



                return true;
            }
            return false;
        },
        connectUrl() {

            let url = "https://manager.korda.cloud"

            if (!this.prod) url = "https://manager-preprod.korda.cloud"
            //if (!this.prod) url = "http://localhost:8081"

            console.log("connectUrl", url)

            return url + "/services/connect/?authapikey=" + this.apikey

        },
        apiUrl() {

            let url = 'https://api.korda.cloud'

            if (!this.prod) url = 'https://preprod.korda.cloud'

            console.log("apiUrl", url)

            return url
        }

    },
    actions: {
        save(param) {
            param = param || JSON.stringify(this.user);
            this.user = JSON.parse(param);

            console.log("save", this.user)
            localStorage.setItem('kc_data', param);
        },
        saveOnCloud() {
            let that = this;
            
            if (that.saveInProgress || !that.apikey) return
            this.saveInProgress = true;
            function loop() {
                let track = that.user.trackings.shift();
                if (!track) {
                    that.saveInProgress = false;
                    that.save()
                    return;
                }

                track.authapikey = that.apikey;
                track.authlogin = that.user.login;
                track.authtoken = that.user.token;
                
                
                window.$.ajax({
                        type: 'POST',
                        data: track,
                        url: that.apiUrl + '/v1/track.add',
                        timeout: 3000
                    })
                    .fail(function() {
                        // Reset savecloud for resend save
                        that.user.trackings.push(track);
                        that.saveInProgress = false;
                    })
                    .done(function(resp) {

                        // If valide => remove track + start next track
                        if (resp && resp.ok) {
                            return loop();
                        }

                        // If fail -> disconnect
                        that.saveInProgress = false;
                        that.user.trackings.push(track);
                        return that.logout();
                    });

            }

            loop();

        },
        logout(param) {
            param = param || "/";
            
            localStorage.removeItem('kc_data');
            sessionStorage.removeItem('new_session');

            this.$reset();

            setTimeout(function() {

              window.location = param;


            }, 200)
            
        },
        saveConf(key, value) {
            this.user[key] = value;
            this.save();
        },
        getConf(key) {
            if (this.user) return this.user[key];
            return undefined;
        },
        setTrack(type, key, value, replace) {
            this.user.trackings = this.user.trackings || [];
            this.user.trackings.push({
                type: type,
                key: key,
                value: value,
                replace: replace,
                date: +new Date()
            });
            this.save();
            this.saveOnCloud()
        },
        getUserTracks(callback, params) {



            let that = this;
            console.log(params)
            //shinte nouveau

            console.log("getUserTracks mode", this.user.isNewer)
            if (this.user.isNewer) {
              callback(that.user.trackings || []);
            } else {
            

              /**/
              let alltracks = [];
              let datas = {};

              

              datas.authapikey = this.apikey;
              datas.authlogin = this.user.login;
              datas.authtoken = this.user.token;

              let more = params.types ? ',type:' + params.types : '';

              // Regroup and order params
              //datas.search = 'apikey:' + datas.authapikey + ',type:parcours_state||parcours_assess||quiz_state||quiz_result||quiz_for||plug_state||autodiag||certificat||favorite_state||current_plug';
              datas.search = 'apikey:' + datas.authapikey + more;
              //datas.regroup = 'type,key';
              datas.sort = 'creation_date:-1';
              datas.limit = 500;
              console.log("date", params.date)
              if (params.date) datas.search += ',creation_date:>' + (params.date);

              // Get all trackings
              (function loop(page) {
                  datas.page = page + 1 || 1;
                  userTracks(datas, function(body) {
                      // Prevent fail
                      if (!body)
                          return callback(alltracks);

                      // Save tracks
                      alltracks = alltracks.concat(body);
                      // If not all event getted
                      if (body.length >= 500) {
                          loop(datas.page);
                      } else {
                          // Start app
                          callback(alltracks.concat(that.user.trackings || []));
                      }
                  }, params.fallbackroute);
              })();
            }

            function userTracks(params, cb, fallbackroute) {
                window.$.ajax({
                        type: 'POST',
                        data: params,
                        url: that.apiUrl+'/v1/track.my',
                        timeout: 3000
                    })
                    .fail(function() { cb(); })
                    .done(function(resp) {
                        console.log("body track", resp)
                        if (resp.ok) {
                            cb(resp.body);
                        } else {
                            that.logout(fallbackroute);
                        }

                    });
            }
            /**/

        },
    },
})