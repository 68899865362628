import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import router from './router';

import './registerServiceWorker';
import VueMobileDetection from "vue-mobile-detection";


import { createDeviceDetector } from "next-vue-device-detector";
export const device = createDeviceDetector()


const pinia = createPinia();




createApp(App).use(router).use($).use(pinia).use(VueMobileDetection).use(device).mount('#app');
