<template>
    <div class="container">
        <h1>Onet Evénementiel</h1>
        <h2 v-if="!$route.params.job">Avant de commencer à réaliser votre prestation, regardez la vidéo correspondant à votre métier.</h2>
        <div class="list">

            <VideoPlayer :video_url="require('@/assets/video/event/hospitality.mp4')" :poster_url="require('@/assets/img/event/hospitality_cover.png')" v-if="$route.params.job == 'hospitality'"/>
            <VideoPlayer :video_url="require('@/assets/video/event/security.mp4')" :poster_url="require('@/assets/img/event/security_cover.png')" v-if="$route.params.job == 'security'"/>
            <VideoPlayer :video_url="require('@/assets/video/event/cleaning.mp4')" :poster_url="require('@/assets/img/event/cleaning_cover.png')" v-if="$route.params.job == 'cleaning'"/>
                <router-link :to="{path:'/'+this.$root.language+'/event/'+(this.$root.fullscreen?'fs/':'')+'hospitality'}" class="item" :class="{'current':job == 'hospitality'}"><img src="@/assets/img/event/hospitality.png"><p>Je suis agent d’accueil</p></router-link>
                <router-link :to="{path:'/'+this.$root.language+'/event/'+(this.$root.fullscreen?'fs/':'')+'security'}" class="item" :class="{'current':job == 'security'}"><img src="@/assets/img/event/security.png"><p>Je suis agent de sécurité</p></router-link>
                <router-link :to="{path:'/'+this.$root.language+'/event/'+(this.$root.fullscreen?'fs/':'')+'cleaning'}" class="item" :class="{'current':job == 'cleaning'}"><img src="@/assets/img/event/cleaning.png"><p>Je suis agent de propreté</p></router-link>
            
        </div>
        
    </div>
</template>
<script>
import VideoPlayer from '@/components/VideoPlayer'

export default {
    name: 'EventTile',
    data() {
        return {

        }
    },
    created () {
    },
    // Mount vuejs
    mounted() {

        this.$root.showNav = true;
        this.$root.showMenu = false;

        if (this.$route.params.job) window.$(".videoplayer").get(0).scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });

        if (this.lng !== 'fr' && this.lng !== 'es') {
            this.$root.$router.push("/"+this.$root.language+"/"+this.lng);
        } else {
            this.$root.language = this.lng
        }

    },
    components: {
      VideoPlayer: VideoPlayer
    },
    methods: {
    },
    watch: {
        $route: function() {

            if (window.$(".videoplayer").get(0)) window.$(".videoplayer").get(0).scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        },
        lng: function(newVal) {
            if (newVal !== 'fr' && newVal !== 'es') {
                this.$root.$router.push("/"+this.$root.language+"/"+newVal);
            } else {
                this.$root.language = this.lng
            }
        },
    },
    props: ['lng', 'job']
}
</script>
<style scoped>
h1 {
    font-size: var(--fs-700);
    font-weight: 700;
    color: var(--clr-blue);
    margin-bottom: 2em;
}

h2 {
    font-size: var(--fs-600);
    font-weight: 700;
    color: var(--clr-blue);
    margin-bottom: 2em;
}

.mobile .container {
    padding: 6em 1em 1em 1em;
}



.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
}

.mobile .list {
    grid-template-columns: 1fr;
}

a.item {
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr 4fr;
    appearance: none;
    border: none;
    border-radius: 1em;
    overflow: hidden;
    align-content: center;
    align-items: center;
    background-color: var(--clr-white);
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.18));
    text-decoration: none;
    font-weight: bold;
    color: var(--clr-body);
}

.mobile a.item {
    grid-template-columns: 1fr 6fr;
}


a.item.current {
    color: var(--clr-white);
    background: linear-gradient(180deg, #023570 0%, #017DAC 100%);
}

.mobile a.item.current {
    grid-row-start: 2;
}

a.item img {
    object-fit: cover;
}

a.item p {
    padding: 1em;
}

.main:not(.mobile) .videoplayer {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 4;
}
img.item {
    /*grid-row-start: 2;
    grid-row-end: 4;*/
}

.videoplayer :deep(video) {
    background: linear-gradient(180deg, #023570 0%, #017DAC 100%);
}

</style>