<template>
    <transition name="fade">
        <div class="container">
                <div class="content">
                    <h1>{{$root.translation[$root.language].bravo}}</h1>
                    <p>{{$root.translation[$root.language].parcours_success}} "{{parcours.title}}", {{$root.translation[$root.language].parcours_download}}</p>
                    <p><button class="certificate" :class="{active:parcours.obtain_date}" @click="printCertificate()"><em>{{$root.translation[$root.language].my_certificate}}</em>
                        </button></p>

                    <p style="text-align: right;"><button class="pass blue" @click="$root.$router.push('/' + this.$root.language + '/learn')"><em>{{$root.translation[$root.language].skip}}</em></button></p>
                </div>
        </div>
    </transition>
</template>
<script>
import { useLearnStore } from '@/stores/learn'
import { kcAuthStore } from '@/stores/kcAuth'

export default {
    name: "LearnReward",
    components: {},
    data() {
        return {
            dataslearn: useLearnStore(),
            auth: kcAuthStore(),
            parcours: {},
            mobiledeploy: false,
        }
    },
    created() {


        this.$root.showNav = true;
        this.$root.showMenu = false;


        this.parcours = this.dataslearn.getParcours(this.id);

        if (!this.parcours.complete) {
            this.$root.$router.push("/" + this.$root.language + "/l/"+this.parcours.id)
        }
        console.log(this.parcours, this.auth.user)


    },
    methods: {
        printCertificate: function() {
            if (this.parcours.obtain_date) {
                let firstname = encodeURIComponent(this.auth.user.infos.firstname);
                var lastname = encodeURIComponent(this.auth.user.infos.lastname);
                var d = new Date(this.parcours.obtain_date);
                var date = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
                var key = encodeURIComponent(btoa(firstname + ' ' + lastname + date));


                var society = [];
                for (var i in this.auth.user.groups) {
                    if (this.auth.user.groups[i].name != "defaut") society.push(this.auth.user.groups[i].name);
                }
                society = society.join(",");


                var url = 'https://onet-learn.korda.cloud/certificats/?firstname=' + firstname + '&lastname=' + lastname + '&date=' + date + '&id=' + this.parcours.id + '&key=' + key + '&society=' + society + '&lng=' + this.$root.language;

                window.open(url, "_new");
            }
        }
    },
    props: ['id', 'lng'],
}
</script>
<style scoped>
    h1 {
        font-size: var(--fs-800);
    }
    .container {
        padding: 1em 2em 2em 1em;
        height: 100%;
    }

    .mobile .container {
        padding: 6em 1em 2em 1em;
    }

    .content {
        border-radius: 1em;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.18));
        min-height: 100%;
        width: 100%;
        background-image: url(@/assets/css/img/bg_win.svg), linear-gradient(180deg, var(--clr-blue) 0%, var(--clr-blue-light) 100%);
        background-size: contain, cover;
        background-position: center bottom;
        background-repeat: no-repeat;
        position: relative;
        padding: 2em;
        color: var(--clr-white);
        display: flex;
        flex-direction: column;
        gap: 5em;
    }

    .content p {
        font-size: var(--fs-600);;
    }

    .content button {
        appearance: none;
        border: none;
        border-radius: 100vw;
        filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.18));
        background-color: var(--clr-white);
        font-size: var(--fs-500);
        line-height: 1;
        padding: 1em 3em 1em 3em;
        position: relative;
        color: var(--clr-blue);
        font-weight: 700;
    }

    .content button.blue {
        background-image: linear-gradient(180deg, var(--clr-blue) 0%, var(--clr-blue-light) 100%);
        color: var(--clr-white);
    }

    .content button:not(:disabled) {
        cursor: pointer;
    }

    .content button:after {
        content: '';
        position: absolute;
        width: 1em;
        height: 1em;
        top: calc(50% - .5em);
        right: 1em;
        background-image: url(@/assets/css/img/icon_arrow_blue.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        transition: all 250ms;
        scale: 1;
        rotate: 180deg;
    }

    .content button.blue:after {
        background-image: url(@/assets/css/img/icon_arrow_white.svg);

    }

    .content button:not(:disabled):hover:after {
        scale: 1.1;
    }

</style>