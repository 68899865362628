import { createRouter, createWebHistory } from 'vue-router';
import GlobalHome from '@/views/GlobalHome.vue';
import LearnContainer from '@/views/LearnContainer.vue';
import LearnList from '@/views/LearnList.vue';
import LearnParcours from '@/views/LearnParcours.vue';
import LearnPlug from '@/views/LearnPlug.vue';
import LearnChoice from '@/views/LearnChoice.vue';
import LearnReward from '@/views/LearnReward.vue';
import GlobalUser from '@/views/GlobalUser.vue';
import NotFound from '@/views/NotFound.vue';
import KcLogin from '@/views/KcLogin.vue';
import FirstSteps from '@/views/FirstSteps.vue';
import ModOp from '@/views/ModOp.vue';
import ScormTest from '@/views/ScormTest.vue';
import EventTile from '@/views/EventTile.vue';
import EventJO from '@/views/EventJO.vue';


const routes = [{ path: '/', redirect: '/fr' },
    {
        name: 'GlobalHome',
        path: '/:lng',
        props: true,
        component: GlobalHome,
        meta: { requiresAuth: false }
    }, {
        name: 'LearnContainer',
        path: '/:lng/learn',
        props: true,
        component: LearnContainer,
        children: [{
                name: 'LearnList',
                path: '',
                component: LearnList,
                props: true,
                meta: { requiresAuth: true }
            },
            {
                name: 'LearnChoice',
                path: '/:lng/learn/c/:society?',
                component: LearnChoice,
                props: true,
                meta: { requiresAuth: true }
            },
            {
                name: 'LearnParcours',
                path: '/:lng/l/:id',
                component: LearnParcours,
                props: true,
                meta: { requiresAuth: true }
            },
            {
                name: 'LearnReward',
                path: '/:lng/l/:id/reward',
                component: LearnReward,
                props: true,
                meta: { requiresAuth: true }
            },
            {
                name: 'LearnPlug',
                path: '/:lng/l/:idparcours/p/:idplug/:part?',
                component: LearnPlug,
                props: true,
                meta: { requiresAuth: true },
            },

        ]
    }, {
        name: 'GlobalUser',
        path: '/:lng/user',
        component: GlobalUser,
        props: true,
        meta: { requiresAuth: true }
    }, {
        name: 'NotFound',
        path: '/:patMatch(.*)',
        component: NotFound,
    }, {
        name: 'KcLogin',
        path: '/:lng/login',
        component: KcLogin,
        props:true,
        meta: { requiresAuth: false }
    }, {
        name: 'FirstSteps',
        path: '/:lng/firststeps',
        component: FirstSteps,
        props:true,
        meta: { requiresAuth: false },
    }, {
        name: 'FirstStepsFullScreen',
        path: '/:lng/firststeps/fs',
        component: FirstSteps,
        meta: { requiresAuth: false, fullScreen: true },
        props: true
    }, {
        name: 'EventJO',
        path: '/:lng/eventint',
        component: EventJO,
        props:true,
        meta: { requiresAuth: false },
    }, {
        name: 'EventJOFullScreen',
        path: '/:lng/eventint/fs',
        component: EventJO,
        meta: { requiresAuth: false, fullScreen: true },
        props: true
    }, {
        name: 'ModOp',
        path: '/:lng/modop',
        component: ModOp,
        props:true,
        meta: { requiresAuth: false }
    }, {
        name: 'EventTile',
        path: '/:lng/event/:job?',
        props:true,
        component: EventTile,
        meta: { requiresAuth: false }
    }, {
        name: 'EventTileFullScreen',
        path: '/:lng/event/fs/:job?',
        component: EventTile,
        meta: { requiresAuth: false, fullScreen: true },
        props: true
    }, {
        name: 'ScormTest',
        path: '/fr/scormtest/:idscorm',
        component: ScormTest,
        meta: { requiresAuth: true }
    }

];

const router = createRouter({
    history: createWebHistory(),
    routes,
})



export default router;