<template>
    <transition name="pop">

        <div class="container" v-if="showme">
            <h1>Modes opératoires</h1>
            <p v-if="!auth.user.token">Connectez-vous pour accéder à tous les contenus, <router-link to="/login?redirect=modop">se connecter</router-link></p>

            <div class="select">
                <fieldset>
                    <legend>Secteur métier</legend>
                    <select @change="filteren_1">
                        <option value="false" selected="true">Tous</option>
                        <option v-for="opt in cards.reduce((a,d) => { if(!a.includes(d.en_1)) a.push(d.en_1); return a; }, [])" :key="opt" :value="opt" :selected="filters.en_1 == opt">{{opt}}</option>
                    </select>
                </fieldset>
            </div>

            <div class="select">
                <fieldset>
                    <legend>Type de prestation à réaliser</legend>
                    <select @change="filteren_2">
                        <option value="false">Toutes</option>
                        <option v-for="opt in cards.reduce((a,d) => { if(!a.includes(d.en_2)) a.push(d.en_2); return a; }, [])" :key="opt" :value="opt" :selected="filters.en_2 == opt">{{opt}}</option>
                    </select>
                </fieldset>
            </div>

            <div class="select">
                <fieldset>
                    <legend>Type de revêtement ou support à traiter</legend>
                    <select @change="filteren_3">
                        <option value="false">Tous</option>
                        <option v-for="opt in filters.results.reduce((a,d) => { if(!a.includes(d.en_3)) a.push(d.en_3); return a; }, [])" :key="opt" :value="opt" :selected="filters.en_3 == opt">{{opt}}</option>
                    </select>
                </fieldset>
            </div>
            <div class="cards">

                <a class="card" v-for="card in filters.results" :key="card.id" :class="card.lock ? 'lock' : ''" :href="card.lock && !auth.user.token ? '' : 'https://onet-learn-modop.korda.cloud/'+card.file" target="_blank" @click="openCard(card)">
                    <div class="bg" :style="'background-image:url(https://onet-learn-modop.korda.cloud/' + card.picture + ');'"></div>
                    <div class="title">{{card.title.toLowerCase()}}</div>
                </a>

                <div class="nofiches" v-if="filters.results.length === 0">Aucune fiche trouvée avec ces filtres</div>

            </div>



        </div>
    </transition>
</template>
<script>
import { useLearnStore } from '@/stores/learn'
import { kcAuthStore } from '@/stores/kcAuth'

export default {
    name: "ModOp",
    data() {
        return {
            dataslearn: useLearnStore(),
            auth: kcAuthStore(),
            showme: false,
            cards:[], 
            filters:{results:[], en_1:false,en_2:false,en_3:false},
        }
    },
    created() {
        let that = this;
        this.$root.showNav = true;
        this.$root.showMenu = false;

        setTimeout(function() {
            that.showme = true;
        }, 100);

        this.init()
        
        this.$root.addPageVisit('modop_home');


    },
    mounted() {
        if (this.lng !== 'fr' && this.lng !== 'es') {
            this.$root.$router.push("/"+this.$root.language+"/"+this.lng);
        } else {
            this.$root.language = this.lng
        }
    },
    watch: {
        lng: function(newVal) {
            if (newVal !== 'fr' && newVal !== 'es') {
                this.$root.$router.push("/"+this.$root.language+"/"+newVal);
            } else {
                this.$root.language = this.lng
            }
        },
    },
    props:['lng'],
    methods: {
        async init() {
            let data = await window.$.post('https://onet-learn-modop.korda.cloud/api/data', { authlogin:this.auth.user.login, authtoken:this.auth.user.token });
            this.cards = data.contents;

            this.cards = this.cards.sort((a, b) => a.title.localeCompare(b.title))

            //console.log("init", this.auth.user.login, this.auth.user.token, this.cards)

            this.buildResults();
        },
                filterCategory(e)
                {
                    this.filters.category = e.target.value === 'false' ? false : e.target.value;
                    this.buildResults();
                },
                filterType(e)
                {
                    this.filters.type = e.target.value === 'false' ? false : e.target.value;
                    this.buildResults();
                },
                filteren_1(e)
                {
                    this.filters.en_1 = e.target.value === 'false' ? false : e.target.value;
                    this.buildResults();
                },
                filteren_2(e)
                {
                    this.filters.en_2 = e.target.value === 'false' ? false : e.target.value;
                    this.buildResults();
                },
                filteren_3(e)
                {
                    this.filters.en_3 = e.target.value === 'false' ? false : e.target.value;
                    this.buildResults();
                },
                buildResults()
                {
                    let that = this;
                    that.filters.results = that.cards
                        .filter(e => (!that.filters.en_1 || e.en_1 === that.filters.en_1) && (!that.filters.en_2 || e.en_2 === that.filters.en_2) && (!that.filters.en_3 || e.en_3 === that.filters.en_3))
                        .map(e => { e.lock = !e.file || (e.public === 0 && typeof that.auth !== 'object'); return e; })
                    ;
                },
                openCard(card)
                {
                    // If card lock
                    this.$root.addPageVisit('modop_'+card.id);
                    if(card.lock)
                    {
                        event.preventDefault();
                        event.stopPropagation();
                        this.$root.$router.push('/login?redirect=modop');
                    }
                }
    }
}
</script>
<style scoped>
    h1 {
        color: var(--clr-blue);
        font-size: var(--fs-700);
        font-weight: 700;
        text-align: left;
        margin-bottom: 1em;
    }
    h2 {
        color: var(--clr-blue);
        font-weight: 700;
    }
    .container {
    }

    .mobile .container {
        padding: 6em 1em 1em 1em;
    }
.nofiches {
    display: inline-block;
    padding: 1em 2em;
    color: var(--clr-red);
    border-radius: 1em;
    background: #e2081421;
    border: .15em solid;
}

.select {
    padding: .75em 1em;
    margin: .75em;
    position: relative;
    display: inline-block;
    width: max(20em, 30%);
    display: inline-block;
    /*min-width: 15em;*/
}
.select legend {
    position: absolute;
    top: -.2em;
    left: .5em;
    padding: 0 .25em;
    z-index: 1;
    line-height: 3em;
    color: #666;
    transition-duration: .16s;
    transition-property: transform,opacity;
    transform-origin: left 50%;
    background-color: #fff;
    transform: scale(.75) translate(.25em,-.75em);
    line-height: inherit;
    background-color: var(--clr-background-default);
}
.select fieldset {
    padding: 0;
}
.select fieldset,
.select select {
    appearance: none;
    background-color: transparent;
    outline: none;
    border: none;
    color: var(--clr-blue);
    cursor: pointer;
    width: 100%;
}
.select::after {
    content: '';
    position: absolute;
    pointer-events: none;
    width: 100%; height: 100%;
    top: 0; left: 0;
    color: var(--clr-blue);
    border-radius: .5em;
    border: 1px solid;
    box-sizing: border-box;
}

.logo {
    margin: auto;
    width: 10em;
    display: block;
    margin-bottom: 2rem;
}

.cards {    
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: center;
    margin-block: 3em;
}

.cards .card {
    flex: 0 0 15em;
    border-radius: 1em;
    box-shadow: 0 0 2em rgba(0,0,0,.1);
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    transition: all 300ms cubic-bezier(0, 0.55, 0.45, 1);
}

@media (max-width: 600px) {
  .cards .card {
    flex: 1 0 15em;
    font-size: 1.2rem;
  }
}

.cards .card .bg {
    width: 100%; height: 14em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.cards .card.lock .title::after {
    content: '';
    position: absolute;
    top: -1.25em; left: 50%;
    padding: 2em;
    background: url(@/assets/css/img/icon_lock.svg) no-repeat center;
    background-size: 50%;
    transform: translate3d(-50%,-50%,0);
    background-color: var(--clr-red);
    border-radius: 100%;
    z-index: 1;
    transition: all 300ms cubic-bezier(0, 0.55, 0.45, 1);
}
.cards .card.lock .bg {
    filter: blur(.3em);
}

.cards .card .title {
    padding: 1em;
    position: absolute;
    bottom: 1em; left: 1em; right: 1em;
    z-index: 1;
    text-transform: capitalize;
    transition: all 300ms cubic-bezier(0, 0.55, 0.45, 1);
}

.cards .card .title::before {
    content: '';
    width: 100%; height: 100%;
    left: 0; top: 0;
    position: absolute;
    height: 100%;
    z-index: -1;
    background: #fff;
    box-shadow: 0 0 2em rgba(0,0,0,.1);
    border-radius: 1em;
    transition: all 300ms cubic-bezier(0, 0.55, 0.45, 1);
}

.cards .card:hover {
    transform: scale(1.1);
    z-index: 9;
    box-shadow: 0 0 4em rgba(0,0,0,.3);
}
.cards .card:hover .title {
    bottom: .5em; left: .5em; right: .5em;
    padding: 1em 1.5em;
}
.cards .card:hover .title::before {
    box-shadow: 0 0 4em rgba(0,0,0,.5);
}
.cards .card.lock:hover .title::after {
    transform: translate3d(-50%,-100%,0);
}

    .pop-enter-active,
    .pop-leave-active {
        transition: all 350ms;
    }

    .pop-enter-from {
      opacity: 0;
      scale: .9;
    }

    .pop-enter-to {
      opacity: 1;
      scale: 1;
    }

    .pop-leave-from {
        display: none;
        opacity: 1;
        scale: 1;
    }

    .pop-leave-to {
        display: none;
        opacity: 0;
        scale: .9;
    }

</style>