<template>
    <transition name="fromright" v-if="display">
        <div class="container">
            <h1>{{auth.user.infos?.firstname}} {{auth.user.infos?.lastname}}</h1>
            <h2>Onet Learn</h2>
            <div v-if="dataslearn.datas.version" class="block">
                <p v-if="dataslearn.isTester"><button @click="changeTester" class="toggle" :aria-pressed="auth.user.isTester"><em>Testeur :</em> <span v-if="dataslearn.isTester">Actuellement, je teste les futurs parcours</span><span v-else>Actuellement, je suis sur l'application comme tout le monde</span></button></p>
                <p v-if="dataslearn.isTester"><button @click="changeNewer" class="toggle" :aria-pressed="auth.user.isNewer"><em>Nouveau :</em> <span v-if="auth.user.isNewer">Actuellement, je teste l'application comme un nouvel utilisateur</span><span v-else>Actuellement, je suis sur l'application comme tout le monde</span></button></p>
                <p><button @click="$root.$router.push({ name: 'LearnChoice'})" class="link"><em>{{$root.translation[$root.language].change_journey}} : </em> {{dataslearn.datas.autodiagnv?.lib}}</button></p>
            </div>
            <h2>{{$root.translation[$root.language].general}}</h2>
            <div class="block">
                <p><button @click="launchConfidentiality()" class="link">{{$root.translation[$root.language].confidentiality}}</button></p>
                <p><button v-if="auth.isLoggedIn" @click="auth.logout('/'+lng)" class="disconnect">{{$root.translation[$root.language].disconnect}}</button></p>
            </div>
        </div>
    </transition>
</template>
<style scoped>
.container {
    text-align: left;
}

h1 {
    font-size: var(--fs-700);
    font-weight: 700;
    color: var(--clr-blue);
    margin-bottom: 1em;
}

h2 {
    margin-top: 1em;
    font-size: var(--fs-600);
}

em {
    font-weight: 700;
}

.mobile .container {
    padding: 6em 1em 1em 1em;
}

button.toggle {
    appearance: none;
    position: relative;
    text-align: left;
    padding-left: 5em;
    padding-right: 1em;
    background-color: transparent;
    border-color: transparent;
    color: var(--clr-blue);
    border-style: solid;
    border-width: 1px;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: var(--fs-500);
    cursor: pointer;
}

button.toggle:before {
    content: '';
    position: absolute;
    width: 3.5em;
    height: 1.8em;
    border-radius: 1000px;
    background-color: var(--clr-grey);
    left: .9em;
    top: calc(50% - .9em);
    transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

button.toggle[aria-pressed="true"]:before {
    background-color: var(--clr-blue);
    transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

button.toggle:after {
    content: '';
    position: absolute;
    width: calc(1.8em - 2px);
    height: calc(1.8em - 2px);
    border-radius: 1000px;
    background-color: var(--clr-white);
    left: calc(.9em + 1px);
    top: calc(50% - .9em + 1px);
    transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
    background-image: url(@/assets/css/img/icon_cross_grey.svg);
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
}

button.toggle[aria-pressed="true"]:after {
    left: 2.7em;
    transition: all 600ms cubic-bezier(0.4, 0, 0.2, 1);
    background-image: url(@/assets/css/img/icon_check_blue.svg);
}

button.link {
    appearance: none;
    position: relative;
    text-align: left;
    padding-left: 5em;
    padding-right: 1em;
    background-color: transparent;
    border-color: transparent;
    color: var(--clr-blue);
    border-style: solid;
    border-width: 1px;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: var(--fs-500);
    cursor: pointer;
    background-image: url(@/assets/css/img/icon_go.svg);
    background-position: left 1.7em center;
    background-size: 2em 2em;
    background-repeat: no-repeat;
}

button.disconnect {
    appearance: none;
    position: relative;
    text-align: left;
    padding-left: 5em;
    padding-right: 1em;
    background-color: transparent;
    border-color: transparent;
    color: var(--clr-blue);
    border-style: solid;
    border-width: 1px;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: var(--fs-500);
    cursor: pointer;
    background-image: url(@/assets/css/img/icon_disconnect_blue.svg);
    background-position: left 1.7em center;
    background-size: 2em 2em;
    background-repeat: no-repeat;
}

.block {
    text-align: left;
    border: solid 1px var(--clr-grey);
    border-radius: 1em;
}


.fromright-enter-active,
.fromright-leave-active {
    transition: all 250ms;
}

.fromright-enter-from {
  translate: 100% 0 0 ;
}

.fromright-enter-to {
  translate: 0 0 0;
  opacity: 1;
}

.fromright-leave-from {
    translate: 0 0 0;
}

.fromright-leave-to {
    translate: 100% 0 0;
}
</style>
<script>
import { useLearnStore } from '@/stores/learn'
import { kcAuthStore } from '@/stores/kcAuth'

export default {
    name: "GlobalUser",
    data() {
        return {
            dataslearn: useLearnStore(),
            auth: kcAuthStore(),
            display: false,
        }
    },
    methods: {
        launchConfidentiality() {
            if (this.$root.language == "es") {
                window.open('https://onet-learn.korda.cloud/confidentiality_es.html', '_new')
            } else {
                window.open('https://onet-learn.korda.cloud/confidentiality.html', '_new')
            }
            
        },
        changeTester() {
            this.auth.user.isTester = !this.auth.user.isTester;
            this.dataslearn.refresh();
            this.$root.$router.push("/learn")
        },
        changeNewer() {
            this.auth.user.isNewer = !this.auth.user.isNewer;
            this.auth.saveConf("learnlastupdate", new Date("01/01/1970").toJSON());
            this.auth.saveConf("learntracks", []);
            this.dataslearn.refresh();
            this.$root.$router.push("/learn")
        }
    },
    created() {

        /*let $ = window.$;*/
        let that = this;

        
        
        this.$root.showNav = true;
        this.$root.showMenu = false;


        setTimeout(function() { console.log("GU", that.dataslearn.fetchUserTrackings()); that.display=true; }, 1000)





        console.log(this.auth.user, this.dataslearn)
    },
    mounted() {
        if (this.lng !== 'fr' && this.lng !== 'es') {
            this.$root.$router.push("/"+this.$root.language+"/"+this.lng);
        } else {
            this.$root.language = this.lng
        }
    },
    watch: {
        lng: function(newVal) {
            if (newVal !== 'fr' && newVal !== 'es') {
                this.$root.$router.push("/"+this.$root.language+"/"+newVal);
            } else {
                this.$root.language = this.lng
            }
        },
    },
    props:['lng']
}
</script>