<template>
    <div class="scormplayer">
        <iframe ref="Iframe" v-if="boot_url" :src="boot_url"></iframe>
        <div v-else>
            <h1>Merci de patienter pendant le chargement...</h1>
            <div class="lds-dual-ring"></div>
        </div>
    </div>
</template>
<script>
import { kcAuthStore } from '@/stores/kcAuth'
export default {
    name: 'ScormPlayer',
    props: {
        id: String,
        folder_url: String,
        end_callback: { type: Function },
        start_callback: { type: Function },
    },
    data() {
        return {
            auth: kcAuthStore(),
            boot_url: undefined,
            debug: undefined,
            scorm_version: undefined
        }
    },
    unmounted() {
        window.API = null;
        window.API_1484_11 = null;
    },
    mounted() {

        let that = this;


        that.auth.getUserTracks(function(tracks) {

            that.auth.saveConf("scormlastupdate", new Date().toJSON());
            //that.auth.saveConf("scormtracks", (that.auth.getConf("scormtracks") || []).concat(tracks));
            that.auth.saveConf("scormtracks", tracks);


            let request = new XMLHttpRequest();
            request.open("GET", '/'+ that.folder_url + "/imsmanifest.xml");
            request.setRequestHeader("Content-Type", "text/xml");
            request.onreadystatechange = function() {
                if (request.readyState === 4 && request.status === 200) {
                    let manifest = JSON.parse(that.xmlToJson(request.responseXML).replace(/\n|\r/g, ''));

                    console.log(manifest)
                    that.scorm_version = manifest.manifest.metadata.schemaversion

                    console.log(that.scorm_version)

                    if (that.scorm_version === "1.2") {

                        let organizations = manifest.manifest.organizations;

                        //find the default organization
                        let organization = organizations.organization.length ? organizations.organization.find(o => o['@identifier'] === organizations['@default']) : organizations.organization;

                        //find the default resource identifier
                        let resource_idenfifier = organization.item.length ? organization.item[0]["@identifierref"] : organization.item["@identifierref"]

                        //find the resource url
                        let resources = manifest.manifest.resources
                        that.boot_url = "/" + that.folder_url + "/" + (resources.resource.length ? resources.resource.find(r => r['@identifier'] === resource_idenfifier)['@href'] : resources.resource['@href']);

                        //that.debug = that.boot_url



                    } else if (that.scorm_version === "CAM 1.3"){
                        let organizations = manifest.manifest.organizations;

                        //find the default organization
                        let organization = organizations.organization.length ? organizations.organization.find(o => o['@identifier'] === organizations['@default']) : organizations.organization;

                        //find the default resource identifier
                        let resource_idenfifier = organization.item.length ? organization.item[0]["@identifierref"] : organization.item["@identifierref"]

                        //find the resource url
                        let resources = manifest.manifest.resources
                        that.boot_url = "/" + that.folder_url + "/" + (resources.resource.length ? resources.resource.find(r => r['@identifier'] === resource_idenfifier)['@href'] : resources.resource['@href']);

                        //that.debug = that.boot_url
                    }



                }
            }
            request.send();






        }, { date: that.auth.getConf("scormlastupdate"), fallbackroute: "/user", types: 'scorm' })



        window.API = {
            LMSInitialize: function() {
                if (that.start_callback) that.start_callback();
                return "true";
            },
            LMSSetValue: function(parameter, value) {
                console.log("LMSSetValue", parameter, value);
                let val = '';
                switch (parameter) {
                    case "cmi.core.lesson_status":
                        that.auth.setTrack('scorm', that.id+'_lesson_status', value, true)
                        break;
                    case "cmi.suspend_data":
                        that.auth.setTrack('scorm', that.id+'_suspend_data', value, true)
                        break;
                    case "cmi.core.lesson_location":

                        break;
                    case "cmi.core.session_time":

                        break;
                    case "cmi.core.exit":
                        if (that.end_callback) that.end_callback();
                        break;
                    case "cmi.core.score.raw":

                        break;
                    case "cmi.core.score.max":

                        break;
                    case "cmi.core.score.min":

                        break;

                }


                return val;
            },
            LMSGetValue: function(parameter) {
                console.log("LMSGetValue", parameter);
                let val = ""
                 switch (parameter) {
                    case "cmi.core.lesson_status":
                        val = that.auth.getConf("scormtracks").filter(a => a.key === that.id+'_lesson_status').sort((a,b)=>b.date-a.date)[0]?.value;
                        
                        break;
                    case "cmi.suspend_data":
                        val = that.auth.getConf("scormtracks").filter(a => a.key === that.id+'_suspend_data').sort((a,b)=>b.date-a.date)[0]?.value;

                        if (val == "undefined" || val == undefined) val = '';
                        break;
                    case "cmi.core.lesson_location":

                        break;
                    case "cmi.core.score.raw":

                        break;
                    case "cmi.core.score.max":

                        break;
                    case "cmi.core.score.min":

                        break;
                    case "cmi.core.student_id":
                        console.log(that.auth.user)
                        val = that.auth.user.infos.id;
                        break;

                }
                console.log("LMSGetValue Value", val);
                return val;
            },
            LMSGetLastError: function() {
                return 0;
            },
            LMSGetErrorString: function() {
                return '';
            },
            LMSCommit: function() {
                return "true";
            },
            LMSFinish: function() {
                return "true";
            },
            LMSGetDiagnostic: function() {

            },

        }

        window.API_1484_11 = {
            Initialize: function() {
                if (that.start_callback) that.start_callback();
                return "true";
            },
            SetValue: function(parameter, value) {
                console.log("LMSSetValue", parameter, value);
                let val = '';
                switch (parameter) {
                    case "cmi.completion_status":
                        that.auth.setTrack('scorm', that.id+'_completion_status', value, true)
                        break;
                    case "cmi.success_status":
                        that.auth.setTrack('scorm', that.id+'_success_status', value, true)
                        break;
                    case "cmi.suspend_data":

                        that.auth.setTrack('scorm', that.id+'_suspend_data', value, true)
                        break;
                    case "cmi.location":

                        break;
                    case "cmi.session_time":

                        break;
                    case "cmi.exit":
                        if (that.end_callback) that.end_callback();
                        break;
                    case "cmi.core.score.raw":

                        break;
                    case "cmi.core.score.max":

                        break;
                    case "cmi.core.score.min":

                        break;

                }


                return val;
            },
            GetValue: function(parameter) {
                console.log("LMSGetValue", parameter);
                let val = ""
                 switch (parameter) {
                    case "cmi.completion_status":
                        val = that.auth.getConf("scormtracks").filter(a => a.key === that.id+'_completion_status').sort((a,b)=>b.date-a.date)[0]?.value;
                        
                        break;
                    case "cmi.success_status":
                        val = that.auth.getConf("scormtracks").filter(a => a.key === that.id+'_success_status').sort((a,b)=>b.date-a.date)[0]?.value;
                        
                        break;
                    case "cmi.suspend_data":
                        console.log("opopopopopopo",that.auth.getConf("scormtracks"))
                        val = that.auth.getConf("scormtracks").filter(a => a.key === that.id+'_suspend_data').sort((a,b)=>parseInt(b.date)-parseInt(a.date))[0]?.value;

                        if (val == "undefined" || val == undefined) val = '';
                        break;
                    case "cmi.location":

                        break;
                    case "cmi.score.raw":

                        break;
                    case "cmi.score.max":

                        break;
                    case "cmi.score.min":

                        break;
                    case "cmi.learner_id":
                        val = that.auth.user.infos.id;
                        break;

                }
                console.log("LMSGetValue Value", val);
                return val;
            },
            GetLastError: function() {
                return 0;
            },
            GetErrorString: function() {
                return '';
            },
            Commit: function() {
                return "true";
            },
            Terminate: function() {
                return "true";
            },
            GetDiagnostic: function() {

            },

        }


        //this.boot_url = "packages/test/index_scorm12.html"
    },
    methods: {
        xmlToJson: function(xml, tab) {
            var X = {
                toObj: function(xml) {
                    var o = {};
                    if (xml.nodeType == 1) { // element node ..
                        if (xml.attributes.length) // element with attributes  ..
                            for (var i = 0; i < xml.attributes.length; i++)
                                o["@" + xml.attributes[i].nodeName] = (xml.attributes[i].nodeValue || "").toString();
                        if (xml.firstChild) { // element has child nodes ..
                            var textChild = 0,
                                cdataChild = 0,
                                hasElementChild = false;
                            for (let n = xml.firstChild; n; n = n.nextSibling) {
                                if (n.nodeType == 1) hasElementChild = true;
                                else if (n.nodeType == 3 && n.nodeValue.match(/[^ \f\n\r\t\v]/)) textChild++; // non-whitespace text
                                else if (n.nodeType == 4) cdataChild++; // cdata section node
                            }
                            if (hasElementChild) {
                                if (textChild < 2 && cdataChild < 2) { // structured element with evtl. a single text or/and cdata node ..
                                    X.removeWhite(xml);
                                    for (let n = xml.firstChild; n; n = n.nextSibling) {
                                        if (n.nodeType == 3) // text node
                                            o["#text"] = X.escape(n.nodeValue);
                                        else if (n.nodeType == 4) // cdata node
                                            o["#cdata"] = X.escape(n.nodeValue);
                                        else if (o[n.nodeName]) { // multiple occurence of element ..
                                            if (o[n.nodeName] instanceof Array)
                                                o[n.nodeName][o[n.nodeName].length] = X.toObj(n);
                                            else
                                                o[n.nodeName] = [o[n.nodeName], X.toObj(n)];
                                        } else // first occurence of element..
                                            o[n.nodeName] = X.toObj(n);
                                    }
                                } else { // mixed content
                                    if (!xml.attributes.length)
                                        o = X.escape(X.innerXml(xml));
                                    else
                                        o["#text"] = X.escape(X.innerXml(xml));
                                }
                            } else if (textChild) { // pure text
                                if (!xml.attributes.length)
                                    o = X.escape(X.innerXml(xml));
                                else
                                    o["#text"] = X.escape(X.innerXml(xml));
                            } else if (cdataChild) { // cdata
                                if (cdataChild > 1)
                                    o = X.escape(X.innerXml(xml));
                                else
                                    for (var n = xml.firstChild; n; n = n.nextSibling)
                                        o["#cdata"] = X.escape(n.nodeValue);
                            }
                        }
                        if (!xml.attributes.length && !xml.firstChild) o = null;
                    } else if (xml.nodeType == 9) { // document.node
                        o = X.toObj(xml.documentElement);
                    } else if (xml.nodeType == 8) { // document.node
                        // c'est un commentaire on s'en fout
                    } else
                        alert("unhandled node type: " + xml.nodeType);
                    return o;
                },
                toJson: function(o, name, ind) {
                    var json = name ? ("\"" + name + "\"") : "";
                    if (o instanceof Array) {
                        for (let i = 0, n = o.length; i < n; i++)
                            o[i] = X.toJson(o[i], "", ind + "\t");
                        json += (name ? ":[" : "[") + (o.length > 1 ? ("\n" + ind + "\t" + o.join(",\n" + ind + "\t") + "\n" + ind) : o.join("")) + "]";
                    } else if (o == null)
                        json += (name && ":") + "null";
                    else if (typeof(o) == "object") {
                        var arr = [];
                        for (let m in o)
                            arr[arr.length] = X.toJson(o[m], m, ind + "\t");
                        json += (name ? ":{" : "{") + (arr.length > 1 ? ("\n" + ind + "\t" + arr.join(",\n" + ind + "\t") + "\n" + ind) : arr.join("")) + "}";
                    } else if (typeof(o) == "string")
                        json += (name && ":") + "\"" + o.toString() + "\"";
                    else
                        json += (name && ":") + o.toString();
                    return json;
                },
                innerXml: function(node) {
                    var s = ""
                    if ("innerHTML" in node)
                        s = node.innerHTML;
                    else {
                        var asXml = function(n) {
                            var s = "";
                            if (n.nodeType == 1) {
                                s += "<" + n.nodeName;
                                for (let i = 0; i < n.attributes.length; i++)
                                    s += " " + n.attributes[i].nodeName + "=\"" + (n.attributes[i].nodeValue || "").toString() + "\"";
                                if (n.firstChild) {
                                    s += ">";
                                    for (let c = n.firstChild; c; c = c.nextSibling)
                                        s += asXml(c);
                                    s += "</" + n.nodeName + ">";
                                } else
                                    s += "/>";
                            } else if (n.nodeType == 3)
                                s += n.nodeValue;
                            else if (n.nodeType == 4)
                                s += "<![CDATA[" + n.nodeValue + "]]>";
                            return s;
                        };
                        for (var c = node.firstChild; c; c = c.nextSibling)
                            s += asXml(c);
                    }
                    return s;
                },
                escape: function(txt) {
                    return txt.replace(/[\\]/g, "\\\\")
                        .replace(/["]/g, '\\"')
                        .replace(/[\n]/g, '\\n')
                        .replace(/[\r]/g, '\\r');
                },
                removeWhite: function(e) {
                    e.normalize();
                    for (var n = e.firstChild; n;) {
                        if (n.nodeType == 3) { // text node
                            if (!n.nodeValue.match(/[^ \f\n\r\t\v]/)) { // pure whitespace text node
                                var nxt = n.nextSibling;
                                e.removeChild(n);
                                n = nxt;
                            } else
                                n = n.nextSibling;
                        } else if (n.nodeType == 1) { // element node
                            X.removeWhite(n);
                            n = n.nextSibling;
                        } else // any other node
                            n = n.nextSibling;
                    }
                    return e;
                }
            };
            if (xml.nodeType == 9) // document node
                xml = xml.documentElement;
            var json = X.toJson(X.toObj(X.removeWhite(xml)), xml.nodeName, "\t");
            return "{\n" + "" + (tab ? json.replace(/\t/g, tab) : json.replace(/\t|\n/g, "")) + "\n}";
        },

    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    font-size: var(--fs-700);
    font-weight: 700;
    color: var(--clr-blue);
    margin-bottom: 2em;
}

.mobile h1 {
    margin-top: 6em;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid var(--clr-blue);
    ;
    border-color: var(--clr-blue-light) transparent var(--clr-blue) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

iframe {
    width: 100%;
    height: 100%;
    border: none;
}
</style>