<template>
    <div class="header">
        <button v-if="promote" @click="$root.$router.push({ name: 'LearnParcours', params: { id: promote?.id } })" :class="{ completed: promote.complete, show: loadedimg[promote.id]  }">
			<img :src="promote.background_url" @load="loadedimg[promote.id] = true">
            <div class="title">
                <p>{{promote.title}}</p>
                <div class="count">
                    <p><em>{{promote.plugs.filter((obj) => obj.completed).length}}</em>/{{promote.plugs.length}} {{$root.translation[$root.language].questions}}</p>
                    <div class="plugs_bar">
                        <div class="bar" :style="{'width': (promote.plugs.filter((obj) => obj.completed).length/promote.plugs.length)*100+'%'}"></div>
                    </div>
                </div>
            </div>
        </button>
    </div>
    <div class="sort" v-if="loadedimg[promote?.id]">
        <h2>{{$root.translation[$root.language].my_journey}}</h2>
        <form>
            <div class="list">
                <label><input type="radio" name="sortjourney" value="all" :checked="sort === 'all'" @change="sortJourney">{{$root.translation[$root.language].all}}</label>
                <label><input type="radio" name="sortjourney" value="notstarted" :checked="sort === 'notstarted'" @change="sortJourney">{{$root.translation[$root.language].discover}}</label>
                <label><input type="radio" name="sortjourney" value="inprogress" :checked="sort === 'inprogress'" @change="sortJourney">{{$root.translation[$root.language].progress}}</label>
                <label><input type="radio" name="sortjourney" value="completed" :checked="sort === 'completed'" @change="sortJourney">{{$root.translation[$root.language].finish}}</label>
            </div>
        </form>
    </div>
    <div class="listparcours" v-if="journey && promote">
        <template v-for="card in journey" :key="card?.id">
            <button @click="$root.$router.push({ name: 'LearnParcours', params: { id: card?.id } })" :class="{ completed: card?.complete, show: loadedimg[card?.id] }">
                <img :src="card?.background_url" @load="loadedimg[card?.id] = true">
                <div class="title">
                    <p>{{card?.title}}</p> 
                    <div class="count">
                        <p><em>{{card?.plugs.filter((obj) => obj?.completed).length}}</em>/{{card?.plugs.length}}{{$root.translation[$root.language].questions}}</p>
                        <div class="plugs_bar">
                            <div class="bar" :style="{'width': (card?.plugs.filter((obj) => obj?.completed).length/card?.plugs.length)*100+'%'}"></div>
                        </div>
                    </div>
                </div>
            </button>
        </template>
    </div>
</template>
<script>
import { useLearnStore } from '@/stores/learn'
export default {
    name: 'LearnList',
    data() {
        return {
            dataslearn: useLearnStore(),
            sort: 'all',
            loadedimg: {},
        }
    },
    // Mount vuejs
    created() {
        //let $ = window.$;
        //let that = this;
        //console.log("mounted List", this.dataslearn.journey);

        /*for (let i in this.dataslearn.datas.parcours) {
        	console.log(this.dataslearn.datas.parcours[i])
        }*/

        //console.log("this is journey", this.dataslearn.journey)

        if (this.dataslearn.journey.length == 0) {

            let codes = this.dataslearn.societyJourneyCodes

            if (codes.length == 0) codes.push("all");

            this.$root.$router.push("/" + this.$root.language + "/learn/c/" + codes.join())
        } else {
            if (this.dataslearn.journey.filter(x => x?.complete).length == 0) {
                this.$root.$router.push("/" + this.$root.language + "/l/" + this.dataslearn.journey[0]?.id)
            }
        }

        this.sort = this.dataslearn.sortjourney || 'all'

        this.$root.showNav = true;
        this.$root.showMenu = false;

        if (this.lng !== 'fr' && this.lng !== 'es') {
            this.$root.$router.push("/"+this.$root.language+"/"+this.lng);
        } else {
            this.$root.language = this.lng
        }


    },
    computed: {
        sorted: function() {
            let j = this.dataslearn.journey
            if (this.sort == "notstarted") {
                j = this.dataslearn.journey.filter(obj => !obj?.started)
            }
            if (this.sort == "inprogress") {
                j = this.dataslearn.journey.filter(obj => !obj?.complete && obj.started)
            }
            if (this.sort == "completed") {
                j = this.dataslearn.journey.filter(obj => obj?.complete)
            }
            return j;
        },
        promote: function() {
            return this.sorted[0];
        },
        journey: function() {
            return this.sorted.slice(1);
        },
    },
    methods: {
        sortJourney: function(e) {
            this.sort = e.target.value;
            this.dataslearn.sortjourney = this.sort;
            e.preventDefault();

        },
    },
    watch: {
        lng: function(newVal) {
            if (newVal !== 'fr' && newVal !== 'es') {
                this.$root.$router.push("/"+this.$root.language+"/"+newVal);
            } else {
                this.$root.language = this.lng
            }
        },
    },
    props: ['lng']
}
</script>
<style scoped>
.header {
    padding: 0 2em 0em 1em;
}

.mobile .header {
    padding: 0 1em 0em 1em;
}

.header button {
    appearance: none;
    border-radius: 1em;
    border: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    aspect-ratio: 26/9;
    padding: 0;
    font-weight: 700;
    color: var(--clr-blue);
    text-align: left;
    opacity: 0;
    scale: .9;
    transition: all 350ms;
}

.header button.show {
    opacity: 1;
    scale: 1;
}

.header button img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header button .title>p {
    font-size: var(--fs-600);
}

.header button .title p em {
    font-size: var(--fs-500);
}

.header button.completed:after {
	content: '';
    position: absolute;
    background-image: url(@/assets/css/img/icon_success.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 4rem;
    height: 4rem;
    left: calc(50% - 2rem);
    top: calc(50% - 2rem);
}

.header button .count {
    background-image: linear-gradient(180deg, #023570 0%, #017DAC 100%);
    color: var(--clr-white);
    width: 100%;
    right: 0;
    bottom: 0;
    height: 4em;
    text-align: left;
    padding: 1em;
}

.header button .count:after {
    content: '';
    position: absolute;
    background-image: url(@/assets/css/img/icon_arrow_white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 2em;
    height: 2em;
    top: calc(50% - 1em);
    right: 2em;
    rotate: 180deg;
    transition: all 350ms;
}

.header button:not(:disabled):hover .count:after {
    right: 1em;
}

.header button .count .plugs_bar {
    width: min(8em, 50%);
}

.header button .title {
    position: absolute;
    width: min(50%, 18em);
    height: calc(100% - 1em);
    top: .5em;
    left: .5em;
    background-color: var(--clr-white);
    border-radius: .5em;
    color: var(--clr-blue);
    padding: 1em;
    overflow: hidden;
}

.mobile .header button {
    border-radius: 0;
    width: calc(100% + 2em);
    position: relative;
    left: -1em;
    aspect-ratio: 10/9;
    background-image: none;
    box-shadow: none;
}

.mobile .header button img {
    aspect-ratio: 16/9;
    height: auto;
    border-radius: 0;
}

.mobile .header button .title {
    width: calc(100% - 2em);
    left: 1em;
    border-radius: 1em;
    top: auto;
    bottom: 1em;
    height: 50%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
}

.mobile .header button .title p {
    font-size: var(--fs-600);
}

.mobile .header button .title p em {
    font-size: var(--fs-700);
}

.mobile .header button .count {
    height: 40%;
}

.mobile .header button.completed:after {
    top: calc(25% - 2rem);
}

.sort {
    text-align: left;

    padding: 2em 2em 2em 1em;
}

.sort h2 {
    font-size: var(--fs-700);
    font-weight: 700;
    margin-bottom: 1em;
}

.sort .list {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
}

.sort input {
    appearance: none;
}

.sort label {
    background-color: var(--clr-white);
    padding: .5em 1em;
    color: var(--clr-blue);
    border-radius: 100vw;
}

.sort label:has(input:checked) {
    background-image: linear-gradient(180deg, #023570 0%, #017DAC 100%);
    color: var(--clr-white);
    font-weight: 700;
}

.listparcours {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
    justify-content: left;
    padding: 0 2em 2em 1em;
}

.mobile .listparcours {
    padding: 0 1em 2em 1em;
}

/*.listparcours button:first-child {
    grid-column-start: 1;
    grid-column-end: 4;
    aspect-ratio: 26/9;
    padding: 0;
}

.mobile .listparcours button:first-child {
    border-radius: 0;
    width: calc(100% + 2em);
    position: relative;
    left: -1em;
    aspect-ratio: 10/9;
    background-image: none;
    box-shadow: none;
}

.mobile .listparcours button:first-child img {
    aspect-ratio: 16/9;
    height: auto;
    border-radius: 0;
}

.mobile .listparcours button:first-child .title {
    width: calc(100% - 2em);
    left: 1em;
    border-radius: 1em;
    top: auto;
    bottom: 1em;
    height: 50%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
}

.mobile .listparcours button:first-child .title p {
    font-size: var(--fs-600);
}

.mobile .listparcours button:first-child .title p em {
    font-size: var(--fs-700);
}

.mobile .listparcours button:first-child .count {
    height: 40%;
}

.mobile .listparcours button:first-child.completed:after {
    top: calc(25% - 2rem);
}

.listparcours button:first-child img {
    width: 100%;
    height: 100%;
    aspect-ratio: auto;
    margin-bottom: 0;
}

.listparcours button:first-child .title {
    position: absolute;
    width: min(50%, 15em);
    height: calc(100% - 1em);
    top: .5em;
    left: .5em;
    background-color: var(--clr-white);
    border-radius: .5em;
    color: var(--clr-blue);
    padding: 1em;
    overflow: hidden;
}*/

.listparcours button {
    appearance: none;
    border-radius: 1em;
    border: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: .5em;
    aspect-ratio: 1/1;
    font-weight: 700;
    color: var(--clr-blue);
    text-align: left;
    scale: .9;
    opacity: 0;
    transition: all 350ms;
}

.listparcours button.show {
    scale: 1;
    opacity: 1;
}

.listparcours button:not(:disabled):hover {
    scale: 1.1;
}

.mobile .listparcours button {
    flex: 0 0 100%;
    aspect-ratio: 3/2;
}

.listparcours button.completed {
    background: linear-gradient(180deg, #023570 0%, #017DAC 100%);
    color: var(--clr-white);
}

.listparcours button img {
    border-radius: .5em;
    margin-bottom: 1em;
    aspect-ratio: 16/9;
    object-fit: cover;
    position: relative;
}

.mobile .listparcours button img {
    aspect-ratio: 26/9;
}

.listparcours button.completed:after {
    content: '';
    position: absolute;
    background-image: url(@/assets/css/img/icon_success.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 4rem;
    height: 4rem;
    left: calc(50% - 2rem);
    top: calc(25% - 2rem);
}

/*.listparcours button:first-child.completed:after {
    left: calc(50% - 2rem);
    top: calc(50% - 2rem);
}

.listparcours button:first-child .count {
    background: linear-gradient(180deg, #023570 0%, #017DAC 100%);
    color: var(--clr-white);
    width: 100%;
    right: 0;
    bottom: 0;
    height: 4em;
    text-align: left;
    padding: 1em;
}

.listparcours button:first-child .count .plugs_bar {
    width: min(8em, 50%);
}*/



@media (max-width: 850px) {
    .listparcours {
        grid-template-columns: 1fr 1fr;
    }

    .header button {
        aspect-ratio: 16/9;
    }
}

@media (max-width: 650px) {

    .header button {
        aspect-ratio: 8/5;
    }

    .header button .title>p {
        font-size: var(--fs-500);
    }

    .listparcours {
        grid-template-columns: 1fr;
    }

    .listparcours button {
        aspect-ratio: 6/5;
    }

    .listparcours button.completed:after {
        top: calc(35% - 2rem);
    }
}

.count {
    font-weight: 400;
    position: absolute;
    bottom: 1em;
    right: 1em;
    font-size: var(--fs-400);
}

.count em {
    font-size: var(--fs-500);
}

.plugs_bar {
    width: 100%;
    height: .5em;
    background-color: var(--clr-blue-xlight);
    border-radius: 100vw;
}

.plugs_bar .bar {
    width: 50%;
    height: 100%;
    background-color: var(--clr-blue-light);
    border-radius: 100vw;
}
</style>