<template>
    <transition name="fade">
        <div class="container" :class="{show:loaded}">
            <div class="header">
				<img :src="parcours.background_url" @load="loaded = true">
                <div class="title" v-if="!$isMobile()">
                    <h1>{{parcours.title}}</h1>
                    <div class="bottom">
                        <p><em>{{parcours.plugs.filter((obj) => obj?.quiz).length}}</em>/{{parcours.plugs.length}}{{$root.translation[$root.language].questions}}</p>
                        <div class="plugs_bar">
                            <div class="bar" :style="{'width': (((obj) => obj?.completed).length/parcours.plugs.length)*100+'%'}"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="actions">
                    <div class="sticky">
                        <button class="launch blue" @click="$root.$router.push({ name: 'LearnPlug', params: { idparcours: parcours.id, idplug:parcours.currentQ ? parcours.plugs[parcours.currentQ].id : parcours.plugs[0].id} })"><em v-if="!parcours.plugs[0].viewed">{{$root.translation[$root.language].start_journey}}</em><em v-if="parcours.plugs[0].viewed">{{$root.translation[$root.language].resume_journey}}</em></button>
                        <button class="certificate" :disabled="!parcours.obtain_date" @click="printCertificate()"><em>{{$root.translation[$root.language].my_certificate}}</em>
                            <p v-if="!parcours.complete">{{$root.translation[$root.language].complete_certificate}}</p>
                        </button>
                    </div>
                </div>
                <div class="parcours">
                    <button :disabled="!$isMobile()" class="deploy" @click="mobiledeploy = !mobiledeploy" :aria-pressed="mobiledeploy">
                        <h2>{{$root.translation[$root.language].your_journey}}</h2>
                    </button>
                    <template v-if="!$isMobile() || mobiledeploy">
                        <button v-for="(plug, index) in parcours.plugs" :key="plug?.id" @click="$root.$router.push({ name: 'LearnPlug', params: { idparcours: parcours.id, idplug:plug?.id } })" :class="{ completed: plug?.completed, viewed: plug?.viewed, current: parcours.currentQ == index}" :style="{ '--local-index': index}" :disabled="(index == 0 || parcours.plugs[index-1]?.completed || plug?.completed)?false:true"><span class="count">{{index+1}}</span>{{plug?.title}}</button>
                    </template>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
import { useLearnStore } from '@/stores/learn'
import { kcAuthStore } from '@/stores/kcAuth'

export default {
    name: "LearnParcours",
    components: {},
    data() {
        return {
            dataslearn: useLearnStore(),
            auth: kcAuthStore(),
            parcours: {},
            mobiledeploy: false,
            loaded: false,
        }
    },
    created() {


        this.$root.showNav = true;
        this.$root.showMenu = false;

        this.parcours = this.dataslearn.getParcours(this.id);
        if (this.dataslearn.journey.filter(x => x?.complete).length == 0 && this.id != this.dataslearn.journey[0]?.id) {
            this.$root.$router.push("/" + this.$root.language + "/learn")
        }


        if (this.lng !== 'fr' && this.lng !== 'es') {
            this.$root.$router.push("/"+this.$root.language+"/"+this.lng);
        } else {
            this.$root.language = this.lng
        }

        //console.log("learnparcours",this.parcours, this.auth.user)


    },
    methods: {
        printCertificate: function() {
            if (this.parcours.obtain_date) {
                let firstname = encodeURIComponent(this.auth.user.infos.firstname);
                var lastname = encodeURIComponent(this.auth.user.infos.lastname);
                var d = new Date(this.parcours.obtain_date);
                var date = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
                var key = encodeURIComponent(btoa(firstname + ' ' + lastname + date));


                var society = [];
                for (var i in this.auth.user.groups) {
                    if (this.auth.user.groups[i].name != "defaut") society.push(this.auth.user.groups[i].name);
                }
                society = society.join(",");


                var url = 'https://onet-learn.korda.cloud/certificats/?firstname=' + firstname + '&lastname=' + lastname + '&date=' + date + '&id=' + this.parcours.id + '&key=' + key + '&society=' + society + '&lng=' + this.lng;

                window.open(url, "_new");
            }
        }
    },
    watch: {
        lng: function(newVal) {
            if (newVal !== 'fr' && newVal !== 'es') {
                this.$root.$router.push("/"+this.$root.language+"/"+newVal);
            } else {
                this.$root.language = this.lng
            }
        },
    },
    props: ['id', 'lng'],
}
</script>
<style scoped>
.container {
    padding: 1em 2em 2em 1em;
    opacity: 0;
    transition: all 350ms;
}

.container.show {
	opacity: 1;
}

.mobile .container {
    padding: 0em 1em 2em 1em;
}

.header {
    border-radius: 1em;
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.18));
    height: 13em;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding: .5em;
    overflow: hidden;
}

.mobile .header {
    border-radius: 0;
    filter: none;
    position: relative;
    left: -1em;
    width: calc(100% + 2em);
}

.header img {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
	object-position: center;
}

.title {
    position: relative;
    width: 17em;
    height: 100%;
    background-color: var(--clr-white);
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.18));
    border-radius: .5em;
    padding: 1em;
    overflow: hidden;
}

.title h1 {
    color: var(--clr-blue);
    font-weight: 700;
    text-align: left;
}

.title .bottom {
    display: block;
    position: absolute;
    width: 100%;
    min-height: 4em;
    text-align: left;
    bottom: 0;
    color: var(--clr-white);
    padding-top: .5em;
}

.title .bottom:after {
    content: '';
    position: absolute;
    top: 0;
    left: -1em;
    width: calc(100%);
    height: 100%;
    background-image: linear-gradient(180deg, var(--clr-blue) 0%, var(--clr-blue-light) 100%);
    z-index: -1;
}

.title .bottom em {
    font-size: 1.2em;
}

.title .bottom .plugs_bar {
    width: 50%;
    height: .5em;
    background-color: var(--clr-blue-xlight);
    border-radius: 100vw;
}

.title .bottom .plugs_bar .bar {
    width: 50%;
    height: 100%;
    background-color: var(--clr-blue-light);
    border-radius: 100vw;
}

.content {
    padding-top: 2em;
    display: grid;
    grid-template-columns: 18em 1fr;
    gap: 2em;
}

.mobile .content {
    display: flex;
    flex-direction: column;
}

.actions {}

.actions .sticky {
    display: flex;
    flex-direction: column;
    gap: 1em;
    text-align: left;
    position: sticky;
    top: 1em;
}



.actions button {
    appearance: none;
    border: none;
    border-radius: 1em;
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.18));
    background-color: var(--clr-white);
    min-height: 10em;
    font-size: var(--fs-400);
    text-align: left;
    line-height: 1;
    padding-left: 7em;
    padding-right: 1em;
    position: relative;
    color: var(--clr-default);
}

.actions button:not(:disabled) {
    cursor: pointer;
}

.actions button:after {
    content: '';
    position: absolute;
    width: 4em;
    height: 4em;
    top: 3em;
    left: 2em;
    background-image: url(@/assets/css/img/icon_certificate_lock.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transition: all 250ms;
    scale: 1;
}
.actions button.certificate:not(:disabled):after {
	background-image: url(@/assets/css/img/icon_coupe.svg);
}

.actions button:not(:disabled):hover:after {
    scale: 1.1;
}

.actions button.launch:after {
    background-image: url(@/assets/css/img/icon_parcours_play.svg);
}

.actions button.blue {
    background-image: linear-gradient(180deg, var(--clr-blue) 0%, var(--clr-blue-light) 100%);
    color: var(--clr-white);
}

.actions button em {
    font-size: var(--fs-600);
    font-weight: 700;
}

.parcours {
    display: grid;
    background-color: var(--clr-white);
    border-radius: 1em;
    padding: 1em;
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.18));
    text-align: left;
}

.parcours h2 {
    font-size: var(--fs-600);
    font-weight: 700;
    padding-block: .5em;
    padding-left: 2.45em;
    background-image: url(@/assets/css/img/icon_parcours.svg);
    background-repeat: no-repeat;
    background-size: 1.2em 1.2em;
    background-position: .7em center;
}

.parcours button.deploy {
    appearance: none;
    background-color: var(--clr-white);
    border: none;
    text-align: left;
    position: relative;
    color: var(--clr-default);
}

.parcours button.deploy:not(:disabled):after {
    content: '';
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    right: 2em;
    top: calc(50% - .75em);
    background-image: url(@/assets/css/img/icon_plug_goto.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.parcours button.deploy[aria-pressed="true"]:after {
    rotate: 90deg;
}

.parcours button:not(.deploy) {
    --local-index: '1';
    color: var(--clr-default);
    appearance: none;
    background-color: var(--clr-white);
    border: none;
    text-align: left;
    text-transform: lowercase;
    min-height: 4em;
    padding-inline: 4em;
    position: relative;
    background-image: linear-gradient(90deg, transparent 0, transparent calc(2em - 2px), var(--clr-grey) calc(2em - 2px), var(--clr-grey) calc(2em - 1px), transparent calc(2em - 1px), transparent 100%), url(@/assets/css/img/icon_plug_goto.svg);
    background-size: 100% 100%, auto 1.1em;
    background-position: center center, center right 2em;
    background-repeat: no-repeat;
    transition: all 250ms;
}

.parcours button:not(.deploy):disabled {
	color: var(--clr-grey);
}

.parcours button:not(.deploy):is(.completed, .viewed, .current) {
    cursor: pointer;
}

.parcours button:not(.deploy):is(.completed, .viewed, .current):hover {
    background-position: center center, center right 1em;
}

.parcours button:not(.deploy):not(.current) {
    background-image: linear-gradient(90deg, transparent 0, transparent 2em, var(--clr-grey) 2em, var(--clr-grey) calc(2em + 1px), transparent calc(2em + 1px), transparent 100%), url(@/assets/css/img/icon_plug_goto.svg);
}

.parcours button:not(.deploy):nth-child(2) {
    background-position: center 2em, center right 2em;
}

.parcours button:not(.deploy):nth-child(2):is(.completed, .viewed, .current):hover {
    background-position: center 2em, center right 1em;
}

.parcours button:not(.deploy):last-child {
    background-position: center -2em, center right 2em;
}

.parcours button:not(.deploy):last-child:is(.completed, .viewed, .current):hover {
    background-position: center -2em, center right 1em;
}

.parcours button:not(.deploy, .current, :last-child):after {
    content: '';
    position: absolute;
    width: calc(100% - 6em);
    height: 1px;
    background-color: var(--clr-grey);
    bottom: 0;
    left: 4em;
}

.parcours button:not(.deploy) .count {
    position: absolute;
    width: 2.2em;
    height: 2.2em;
    background-color: var(--clr-white);
    background-image: url(@/assets/css/img/icon_plug.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90% 90%;
    border-radius: 50%;
    top: calc(50% - 1.1em);
    left: .9em;
    line-height: 2.2em;
    text-align: center;
    font-weight: 700;
}

.parcours button:not(.deploy).viewed .count {
    background-image: url(@/assets/css/img/icon_plug_viewed.svg);
    color: transparent;
}

.parcours button:not(.deploy).completed .count {
    background-image: url(@/assets/css/img/icon_plug_completed.svg);
    color: transparent;
}

.parcours button:not(.deploy):first-letter {
    text-transform: capitalize
}

.parcours button:not(.deploy).current {
    border: solid var(--clr-blue) 2px;
    color: var(--clr-blue);
    font-weight: 700;
}
</style>