<template>
    <transition name="pop">
        <div class="container" v-if="showme && $root.language == 'fr'">
            <h1>{{$root.translation[$root.language].event_fundamentals}}</h1>
            <div class="list">
                <template v-for="(step, index) in firststeps" :key="index">

                    <div :class="{active:step.active}">
                        <button @click="setActive(index)" v-if="!step.active" :class="{clicked:step.clicked}"><img :src="step.image_url">
                        </button>
                        <transition name="pop">
                            <div v-if="step.active" @vnodeMounted="scrollto">
                                <h2>{{step.title}}</h2>
                                <VideoPlayer v-if="step.video_url" :video_url="step.video_url" :poster_url="step.image_url" />
                                <div class="parts" v-if="step.parts">
                                    <div v-for="part in step.parts" :key="part" class="part" :class="{block:part.steps.length===1, enum:part.steps.filter((obj) => obj.text.length > 36).length > 0, big:part.steps.length===2 || part.type === 'big'}">
                                      <div class="sounded"><button @click="playPartSounds(part)"><img src="@/assets/css/img/icon_sound.svg"></button></div>
                                        <div class="icon" v-if="part.icon"><img :src="part.icon"></div>
                                        <h3 :class="[part.title_sound_url == currentSound ? 'currentsound' : '']">{{part.title}}</h3>
                                        <ul>
                                            <li v-for="s in part.steps" :key="s"  :class="[s.sound_url == currentSound ? 'currentsound' : '']"><img :src="s.icon">
                                                <div v-html="s.text"></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div v-if="step.equipment" class="pictos equipment block">
                                    <h3>{{$root.translation[$root.language].equipment}}</h3>
                                    <ul>
                                        <li v-for="item in step.equipment" :key="item.icon"><img :src="'https://onet-learn.korda.cloud/' + 'export/icons/' + 'equipment/'+item.icon + '.svg'">{{item.text}}</li>
                                    </ul>
                                </div>
                                <div v-if="step.prohibitions" class="pictos prohibitions block">
                                    <h2>{{$root.translation[$root.language].prohibitions}}</h2>
                                    <ul>
                                        <li v-for="item in step.prohibitions" :key="item.icon">
                                            <div class="picto"><img :src="'https://onet-learn.korda.cloud/' + 'export/icons/' + 'prohibitions/'+item.icon + '.svg'"></div>{{item.text}}
                                        </li>
                                    </ul>
                                </div>

                                <div v-if="step.materiels" class="pictos materiels block">
                                    <h2>{{$root.translation[$root.language].materiels}}</h2>
                                    <ul>
                                        <li v-for="item in step.materiels" :key="item.icon"><img :src="'https://onet-learn.korda.cloud/' + 'export/icons/' + 'materiels/'+item.icon + '.svg'">{{item.text}}</li>
                                    </ul>
                                </div>
                                <div v-if="step.advices" class="cards advices block">
                                    <h2>{{$root.translation[$root.language].advices}}</h2>
                                    <ul>
                                        <li v-for="item in step.advices" :key="item.icon" :class="item.class">
                                            <div class="picto"><img :src="'https://onet-learn.korda.cloud/' + 'export/icons/' + 'advices/'+item.icon + '.svg'"></div>{{item.text}}
                                        </li>
                                    </ul>
                                </div>

                                <div v-if="step.downloads" class="downloads block">
                                    <div v-for="item in step.downloads" :key="item">
                                        <a :href="'https://onet-learn.korda.cloud/downloads'+item.content" target="_blank">{{item.title}}</a>
                                        
                                    </div>
                                </div>

                                <div v-if="step.steps" class="steps block">
                                    <h2>{{$root.translation[$root.language].steps}}</h2>
                                    <ol>
                                        <li v-for="item in step.steps" :key="item.icon"><img :src="'https://onet-learn.korda.cloud/export/' + dataslearn.getImageDensity() + '_' + item.icon + '.jpg'">
                                            <div v-html="item.text" class="deephtml"></div>
                                        </li>
                                    </ol>
                                </div>

                                <div class="quiz" v-if="step.quiz">
                                    <div class="sounded"><button @click="playQuestionSounds(step.quiz)"><img src="@/assets/css/img/icon_sound.svg"></button></div>
                                    <h3 :class="[step.quiz.question_sound_url == currentSound ? 'currentsound' : '']">{{step.quiz.question}}</h3>
                                    <div>
                                        <form @submit="validQuiz" :id="index">
                                            <div class="answers" :class="step.quiz.type">
                                                <label v-for="(answer, ia) in step.quiz.answers" :key="ia" :for="step.id + '_' + index + '_' + ia" :class="[step.quiz.correct ? answer.answer : '', answer.sound_url == currentSound ? 'currentsound' : '']">
                                                    <img :src="answer.background_url">
                                                    <input :type="step.quiz.answers.length>2?'checkbox':'radio'" name="quiz" :id="step.id + '_' + index + '_' + ia" :value="answer" v-model="step.quiz.selected" :disabled="step.quiz.correct">
                                                    <p>{{answer.text}}</p>
                                                </label>
                                            </div>
                                            <button v-if="!step.quiz.correct" :disabled="step.quiz.selected.length == 0" class="next">Valider</button>
                                        </form>
                                    </div>
                                    <div v-if="step.quiz.correct" class="debrief" @vnodeMounted="scrollto">
                                        <h4 v-if="(step.quiz.answers.length<=2 && step.quiz.selected.answer == 'good')||(step.quiz.answers.length>2 && step.quiz.selected.length == step.quiz.answers.filter((obj) => obj.answer === 'good').length && step.quiz.selected.filter((obj) => obj.answer === 'good').length == step.quiz.answers.filter((obj) => obj.answer === 'good').length)" class="debriefgood">Bonne réponse</h4>
                                        <h4 v-else class="debriefbad">Mauvaise réponse</h4>
                                        <div class="content" :class="[step.quiz.debrief_sound_url == currentSound ? 'currentsound' : '']" v-html="step.quiz.debrief"></div>
                                        <img v-for="bg in step.quiz.debrief_backgrounds_url" :key="bg" :src="bg">
                                    </div>
                                </div>
                                <div class="bonus" v-if="step.videob">
                                    <div v-for="video in step.videob" :key="video">
                                        <h3>{{video.text}}</h3>
                                        <VideoPlayer :video_url="video.url" :poster_url="video.icon" />
                                    </div>
                                </div>
                                <button v-if="index < (firststeps.length - 1)" class="next" @click="setActive(index+1)">Suivant</button>
                            </div>
                        </transition>
                    </div>
                </template>
            </div>
            <!--<video crossorigin playsinline="playsinline" webkit-playsinline="webkit-playsinline" controls>
                            <source src="@/assets/video/seq5_ONET.mp4" type="video/mp4" @error="catcherrorvideo = true"/>
                        </video>

                        <div v-if="catcherrorvideo">Erreur Video : Désactivez votre ad-blocker</div>-->
        </div>
    </transition>
</template>
<script>
import { useLearnStore } from '@/stores/learn'
import { kcAuthStore } from '@/stores/kcAuth'
import VideoPlayer from '@/components/VideoPlayer'

export default {
    name: "EventJO",
    components: {
        VideoPlayer: VideoPlayer
    },
    watch: {
        currentSound(newVal) {
            if (newVal != '')
                setTimeout(function() {
                    if (window.$(".currentsound").get(0)) window.$(".currentsound").get(0).scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                }, 10)

            //
        },
        lng: function(newVal) {
            if (newVal !== 'fr' && newVal !== 'es') {
                this.$root.$router.push("/"+this.$root.language+"/"+newVal);
            } else {
                this.$root.language = this.lng
            }
        },
    },
    mounted() {



        if (this.lng !== 'fr' && this.lng !== 'es') {
            this.$root.$router.push("/"+this.$root.language+"/"+this.lng);
        } else {
            this.$root.language = this.lng
        }
        
    },
    /*props: {
        fullscreen: {
            type: Boolean,
            default: false
        },
        lng:{default:'fr'}
    },*/
    props: ['lng', 'fullscreen'],
    data() {
        return {
            dataslearn: useLearnStore(),
            auth: kcAuthStore(),
            showme: false,
            isplaysound: false,
            sounds_cue: Audio,
            currentSound: '',
            firststeps : undefined,
            firststeps_es:[],
            firststeps_fr: [],
        }
    },
    methods: {
        scrollto: function(e) {
            setTimeout(function() {
                e.el.scrollIntoView({ behavior: "smooth" });
            }, 100)
        },
        setActive(index) {
            this.firststeps.map(a => a.active = false);
            let step = this.firststeps[index];
            this.$root.addPageVisit('jo_'+step.id);
            step.active = true;
            step.clicked = true;
        },
        validQuiz: function(e) {
            //console.log("validQuiz", e.target.id, this.firststeps[e.target.id].quiz, this.isplaysound);

            this.firststeps[e.target.id].quiz.correct = true;
            let that = this;

            if (this.isplaysound) {
                this.sounds_cue.pause();
                this.sounds_cue = new Audio(this.firststeps[e.target.id].quiz.debrief_sound_url);
                this.currentSound = this.firststeps[e.target.id].quiz.debrief_sound_url;
                this.sounds_cue.play();
                this.isplaysound = false;

                this.sounds_cue.addEventListener('ended', function() {
                    that.currentSound = '';
                });
            }

            e.preventDefault();
        },
        playQuestionSounds(q) {

            let playdeb = true;
            let i = 0;
            let that = this;

            that.isplaysound = true;

            this.sounds_cue.pause();
            this.sounds_cue = new Audio(q.question_sound_url);
            this.currentSound = q.question_sound_url;

            this.sounds_cue.addEventListener('ended', function() {

                if (i < q.answers.length) {
                    that.sounds_cue.src = q.answers[i].sound_url;
                    that.currentSound = q.answers[i].sound_url;
                    that.sounds_cue.play();
                    i++;
                } else {

                    if (q.correct) {
                        if (playdeb) {

                            that.sounds_cue.src = q.debrief_sound_url;
                            that.currentSound = q.debrief_sound_url;
                            that.sounds_cue.play();

                            playdeb = false;
                        } else {
                            that.currentSound = '';
                        }
                    } else {
                        that.currentSound = '';
                    }



                }


            }, false);

            this.sounds_cue.play();
        },
        playPartSounds(part) {

            //console.log(part)

            let i = 0;
            let that = this;

            that.isplaysound = true;

            this.sounds_cue.pause();
            this.sounds_cue = new Audio(part.title_sound_url);
            this.currentSound = part.title_sound_url;

            this.sounds_cue.addEventListener('ended', function() {

                if (i < part.steps.length) {
                    that.sounds_cue.src = part.steps[i].sound_url;
                    that.currentSound = part.steps[i].sound_url;
                    that.sounds_cue.play();
                    i++;
                } else {
                    that.currentSound = '';
                }


            }, false);

            this.sounds_cue.play();
        },
    },
    created() {
        let that = this;
        this.$root.showNav = true;
        this.$root.showMenu = false;

        let baseURL = "https://onet-learn.korda.cloud/";

        

        async function getMPP(cb) {
            let serve = 'https://onet-learn.korda.cloud/'

            let the_url = serve + 'v3/jo'


            let options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ language: that.lng })
            };

            let response = await fetch(the_url, options);

            let stream = await response.json();

            that.firststeps = stream.plugs

            cb();


        }

        
        getMPP(function() {


            

            if (!that.firststeps.loaded) {
                that.firststeps.map(a => {
                    a.image_url = baseURL + "export/" + that.dataslearn.getImageDensity() + "_" + a.image + ".jpg";
                    if (a.quiz) {
                        a.quiz = a.quiz.default["1"]
                        a.quiz.selected = [];
                        a.quiz.question_sound_url = baseURL + "sound/" + a.quiz.question_sound;
                        a.quiz.debrief_sound_url = baseURL + "sound/" + a.quiz.debrief_sound;
                        a.quiz.answers.map(b => {
                            b.sound_url = baseURL + "sound/" + b.sound;
                            b.background_url = baseURL + "export/" + that.dataslearn.getImageDensity() + "_" + b.background + ".jpg";
                        })
                        a.quiz.debrief = a.quiz.debrief.replace(/\*(.+?)[*@]/gi, "<b>$1</b>");
                        a.quiz.debrief_backgrounds_url = a.quiz.debrief_backgrounds.map(a => baseURL + "export/" + that.dataslearn.getImageDensity() + "_" + a + ".jpg")
                    }
                    if (a.parts) {
                        a.parts.map(b => {
                            if (b.icon) b.icon = require('@/assets/css/img/' + b.icon + '.svg');
                            if (b.title_sound) b.title_sound_url =  baseURL + "sound/" + b.title_sound;
                            b.steps.map(c => {
                                c.text = c.text.replace(/\*(.+?)[*@]/gi, "<b>$1</b>");
                                if (c.sound) c.sound_url = baseURL + "sound/" + c.sound;
                                if (b.type == "icons") {
                                    c.icon = baseURL + "export/icons/mpp/" + c.icon + ".svg";
                                } else {
                                    c.icon = baseURL + "export/" + that.dataslearn.getImageDensity() + "_" + c.icon + ".jpg";
                                }
                            });
                        });
                    }
                    if (a.videob) {
                        a.videob.map(b => {
                            b.icon = baseURL + "export/" + that.dataslearn.getImageDensity() + "_" + b.icon + ".jpg";
                        });
                    }
                });

                that.firststeps.loaded = true;
            }

            //initialize audio
            that.sounds_cue = new Audio();


            that.$root.addPageVisit('jo_home');



            setTimeout(function() {
                that.showme = true;
            }, 100)
        });
    }
}
</script>
<style scoped>
h1 {
    color: var(--clr-blue);
    font-size: var(--fs-700);
    font-weight: 700;
    text-align: left;
    margin-bottom: 1em;
}

h2 {
    color: var(--clr-blue);
    font-weight: 700;
    margin-bottom: 1em;
}

h3 {
    color: var(--clr-blue);
    font-weight: 700;
    margin-bottom: 1em;
}

h3 {
    transition: all 350ms;
    scale: 1;
}

h3.currentsound {
    font-weight: 700;
    scale: 1.1;
}

li {
    list-style: none;
}

:deep(b) {
    font-weight: 700;
}

input {
    appearance: none;
}

.container {
    display: flex;
    flex-direction: column;
}

.mobile .container {
    padding: 6em 1em 1em 1em;
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1em;
}

.list>div {
    border-radius: 1em;
    /*overflow: hidden;*/
}

.list>div.active {
    grid-column: 1 / 5;
    grid-row: 3 / 4;
    padding-top: 2em;
    min-height: 10em;
}

@media (max-width: 1050px) {
    .mobile .list {
        grid-template-columns: 1fr 1fr;
    }

    .mobile .list>div.active {
        padding-top: 2em;
        grid-column: 1 / 3;
        grid-row: 5 / 6;
    }
}

.list>div>button {
    width: 100%;
    height: 100%;
    appearance: none;
    border: none;
    border-radius: 1em;
    overflow: hidden;
    display: flex;
    color: var(--clr-white);
    font-size: var(--fs-300);
    font-weight: 700;

    background: linear-gradient(180deg, #023570 0%, #017DAC 100%);
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
    position: relative;

}

.list>div>button.clicked {
    filter: grayscale(100%);
}

.list>div>button>img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
}

.list>div>button>p {
    padding: .5em;
    text-align: center;
    position: absolute;
    background: linear-gradient(180deg, #023570 0%, #017DAC 100%);
    display: block;
    width: 100%;
    bottom: 0;
}

.mobile .videoplayer {
    margin-bottom: 3em;
}

.bonus {
    margin-block: 2em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}

@media (max-width: 1050px) {
    .bonus {
        padding-top: 3em;
        grid-template-columns: 1fr;
    }
}

.part {
    background-color: var(--clr-white);
    margin-block: 3em;
    padding: 3em 2em 2em 2em;
    border: solid 1px var(--clr-blue);
    border-radius: 1em;
    position: relative;
}

.part .icon {
    position: absolute;
    width: 4em;
    height: 4em;
    border-radius: 50%;
    top: -2em;
    left: calc(50% - 2em);
}

.part:not(.block, .enum) ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
}

.part:is(.enum) ul {
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
}

.part:not(.block, .enum) li {
    display: flex;
    flex-direction: column;
    flex: 0 0 5em;
    text-align: center;
    align-items: center;
}

.part:is(.big) li {
    flex: 0 0 40%;
}

.mobile .part:is(.big) li {
    flex: 0 0 100%;
}

.part:is(.block, .enum) li {
    display: grid;
    grid-template-columns: 8em 1fr;
    gap: 1em;
    text-align: left;
    align-items: center;
}

.mobile .part:is(.block, .enum) li {
    grid-template-columns: 5em 1fr;
}


.mobile .part.big.enum li {
    grid-template-columns: 1fr;
}

.part.big.enum :deep(ul) {
    margin-left: 1em;
}

.mobile .part:is(.block, .enum) li div {
    overflow-wrap: anywhere;
}

.part li img {
    height: 5em;
    width: auto;
    object-fit: contain;
}

.part:is(.block) li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.part li {
  scale:1;
  transition: all 350ms;
}

.part li.currentsound {
  scale:1.1;

  font-weight: 700;
}

.quiz {
    padding: 4em 2em 2em 2em;
    margin-block: 3em;
    background-color: var(--clr-white);
    border: solid 1px var(--clr-blue);
    border-radius: 1em;
    position: relative;
}

.mobile .quiz {
    padding: 3em .5em .5em .5em;
}

.quiz:after {
    content: '';
    position: absolute;
    width: 4em;
    height: 4em;
    border-radius: 50%;
    top: -2em;
    left: calc(50% - 2em);
    background-color: var(--clr-blue);
    background-image: url(@/assets/css/img/quiz_title_icon.svg);
    background-size: 90% 90%;
    background-repeat: no-repeat;
    background-position: center center;
}

.quiz h3 {
    transition: all 350ms;
}

.quiz h3.currentsound {
    font-weight: 700;
    scale: 1.1;
}

.quiz .answers {
    display: flex;
    gap: 1em;
    justify-content: center;

    flex-wrap: wrap;
}

.quiz label {
    flex: 0 0 15em;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    background-color: var(--clr-white);
    padding-bottom: 1em;
    scale: 1;
    transition: all 250ms;
}

.mobile .quiz label {
    flex: 0 0 40%;
}

.quiz label.currentsound {
    scale: 1.1;
}

.quiz .visual label {
    flex: 0 0 25em;
}

.quiz .list label {
    flex: 0 0 100%;
    flex-direction: row;
    padding: 0;
}

.quiz .list label img {
    width: 40%;
}

.quiz label:after {
    position: absolute;
    right: .5em;
    bottom: .5em;
    width: 1em;
    height: 1em;
    background-image: url(@/assets/css/img/icon_thumbup.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.quiz label.good {
    background-color: var(--clr-good);
    color: var(--clr-white);
}

.quiz label.good:after {
    content: '';
}

.quiz label.bad {
    background-color: var(--clr-bad);
    color: var(--clr-white);
}

.quiz label.bad:after {
    content: '';
    rotate: 180deg;
}

.quiz label input {
    position: relative;
    width: 3em;
    height: 3em;
    z-index: 1;
    left: calc(50% - 1.5em);
    top: -1.5em;
    background-image: url(@/assets/css/img/icon_checkbox_grey.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.quiz .list label input {
    position: absolute;
    flex: 0 0 3em;
    width: 3em;
    height: 3em;
    left: calc(40% - 1.5em);
    top: calc(50% - 1.5em);
}

.quiz label input:checked {
    background-image: url(@/assets/css/img/icon_checkbox_blue.svg);
}

.quiz label p {
    position: relative;
    margin-inline: .5em;
    top: -1em;
}

.quiz .list label p {
    top: 0;
    text-align: left;
    padding: 1em;
}

.quiz .debrief {
    margin-top: 2em;
}

.quiz .debrief h4 {
    font-size: var(--fs-600);
    font-weight: 700;
    margin-block: 2em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.quiz .debrief h4.debriefbad {
    color: var(--clr-bad);
    background-image: url(@/assets/css/img/icon_thumb_bad.svg);
}

.quiz .debrief h4.debriefgood {
    color: var(--clr-good);
    background-image: url(@/assets/css/img/icon_thumb_good.svg);
}

.quiz .debrief .content {
    transition: all 350ms;
}

.quiz .debrief .content.currentsound {
    font-weight: 700;
    scale: 1.1;
}

.quiz .debrief p {
    margin-block: 1em;
}


.quiz .debrief :deep(ul) {
    list-style-position: inside;
}

.quiz .debrief :deep(li) {}

.quiz .debrief :deep(li::marker) {}

.quiz .debrief img {
    aspect-ratio: 16/9;
    object-fit: cover;
    position: relative;
    width: 100%;
    border-radius: 1em;
    margin-top: 1em;
}

.block {
    background-color: var(--clr-white);
    border-radius: 1em;
    padding: 3em 2em 2em 2em;
    margin-block: 3em;
    border: solid 1px var(--clr-blue);
}

.block {
    position: relative;
}

.block:after {
    content: '';
    position: absolute;
    background-color: var(--clr-background-default);
    width: 4em;
    height: 4em;
    border-radius: 50%;
    top: -2em;
    left: calc(50% - 2em);
    background-color: var(--clr-blue);
    background-image: var(--bg-url);
    background-size: 70% 70%;
    background-repeat: no-repeat;
    background-position: center center;
}

.block.prohibitions {
    border-color: var(--clr-bad);
}

.block.prohibitions:after {
    background-color: var(--clr-bad);
}

.block.gammes {
    --bg-url: url(@/assets/css/img/icon_gammes.svg);
}

.block.prohibitions {
    --bg-url: url(@/assets/css/img/icon_prohibition_pale.svg);
}

.block.materiels {
    --bg-url: url(@/assets/css/img/icon_materials.svg);
}

.block.advices {
    --bg-url: url(@/assets/css/img/icon_advices.svg);
}

.block.steps {
    --bg-url: url(@/assets/css/img/icon_steps.svg);
}

.block.function {
    --bg-url: url(@/assets/css/img/icon_function.svg);
}

.block.gammes {
    --bg-url: url(@/assets/css/img/icon_gammes.svg);
}

.block.equipment {
    --bg-url: url(@/assets/css/img/icon_equipment.svg);
}

.block.downloads {
    --bg-url: url(@/assets/css/img/icon_download.svg);
}
.pictos ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
}

.pictos li {
    flex: 0 0 5em;
    text-align: center;
}

.pictos.securite_train img {
    background-color: var(--clr-blue);
    border-radius: 50%;
    padding: .5em;
    margin-inline: auto;
}

.pictos.equipment img {
    background-color: var(--clr-blue);
    border-radius: 50%;
    padding: .5em;
    margin-inline: auto;
}

.pictos.composition img {
    background-color: var(--clr-white);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
    border-radius: 50%;
    padding: .5em;
    margin-inline: auto;
    margin-bottom: .5em;
}

.pictos.prohibitions .picto {
    padding: .5em;
    position: relative;
}

.pictos.prohibitions .picto img {
    margin-inline: auto;
}

.pictos.prohibitions .picto:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(@/assets/css/img/icon_prohibition.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.cards ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
}

.cards li {
    flex: 0 1 12em;
    text-align: center;
    aspect-ratio: 4/5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 4.5em 1em 1em 1em;
}

.cards li img {
    margin-inline: auto;
}

.cards li .picto {

    margin-bottom: .5em;
}

.cards li.do {
    background-image: url(@/assets/css/img/do.svg);
}

.cards li.do .picto img {
    border: solid .4em var(--clr-blue);
    border-radius: 50%;
}

.cards li.dont {
    background-image: url(@/assets/css/img/dont.svg);
}

.cards li.dont .picto img {
    border: solid .4em transparent;
}

.cards li.dont .picto {
    background-image: url(@/assets/css/img/icon_prohibition.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

:is(.steps, .critere_qualite, .gammes, .bd, .types) img {
    width: min(30em, 50%);
    border-radius: 1em;
}

.mobile :is(.steps, .types) img {
    width: 100%;
}

.mobile .bd img {
    width: 100%;
}

:is(.steps, .critere_qualite, .gammes, .bd, .types) li {
    display: flex;
    gap: 3em;
    align-content: center;
    align-items: center;
    position: relative;
    counter-increment: count 1;
}

.mobile :is(.steps, .types) li {
    flex-direction: column;
}

.mobile .bd li {
    flex-direction: column;
}

:is(.steps, .critere_qualite, .gammes, .bd, .types) li.currentsound {
    font-weight: bold;
}

:is(.steps, .critere_qualite, .gammes, .bd, .types) :is(ol, ul) {
    display: flex;
    flex-direction: column;
    gap: 2em;
    counter-reset: count 0;
}

.downloads {
    text-align: left;
    padding-block: 3em;
    padding-inline: 3em;
}


.texts {
    text-align: left;
}

.texts > div {
    padding-block: 3em;
    padding-inline: 3em;
}

.texts :deep(b) {
    font-weight: 700;
}

.texts :deep(p) {
    margin-block: 1em;
}

.texts :deep(ul) {
    margin-left: 2em;
    margin-block: 1em;
}

.texts :deep(ol) {
    margin-left: 2em;
    margin-block: 1em;
}

.steps li:after {
    content: counter(count, decimal);

    position: absolute;
    background-color: var(--clr-blue);
    width: 2em;
    height: 2em;
    color: var(--clr-white);
    line-height: 2em;
    border-radius: 50%;
    font-weight: 700;
    top: calc(50% - 1em);
    left: calc(min(50%, 30em) + .5em);
}

.mobile .steps li:after {
    top: 1em;
    left: 1em;
}



.function div {
    display: flex;
    gap: 1em;
    align-content: center;
    align-items: center;
    position: relative;
}

.function img {
    width: min(30em, 50%);
    border-radius: 1em;
}








.sounded {
    position: sticky;
    top: 4em;
    right: 0;
    z-index: 1;
}

.sounded button {
    position: absolute;
    right: -4em;
    background-color: var(--clr-white);
    padding: .5em 2em .5em .5em;
    border: none;
    border-radius: 100vw 0 0 100vw;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
    cursor: pointer;
}

.mobile .sounded button {
    right: -3em;
    top: -4.5em;
}

.sounded button img {
    width: 2em;
    height: 2em;
}

button.next {
    margin-block: 2em;
    padding: 1em 4em;
    border: none;
    border-radius: 100vw;
    background-image: linear-gradient(180deg, #0565A9 0%, #023570 100%);
    box-shadow: 0 4px 16px 0 rgb(2 53 112 / 40%);
    color: #fff;
    text-align: center;
    font-weight: 700;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
}

button.next:disabled {
    opacity: .5;
    cursor: default;
}


.pop-enter-active,
.pop-leave-active {
    transition: all 350ms;
}

.pop-enter-from {
    opacity: 0;
    scale: .9;
}

.pop-enter-to {
    opacity: 1;
    scale: 1;
}

.pop-leave-from {
    display: none;
    opacity: 1;
    scale: 1;
}

.pop-leave-to {
    display: none;
    opacity: 0;
    scale: .9;
}
</style>