<template>
    <div class="container">
        <h1>{{$root.translation[$root.language].learn_choice}}</h1>
    <div class="list">
        <button v-for="parcours in dataslearn.datas.result_autodiagnv.filter((obj) => society ? obj.for.indexOf(society) !== -1 : obj)" :key="parcours.choice" @click="setChoiceParcours(parcours.choice)">
            <img :src="dataslearn.getParcours(parcours.choice).background_url">
            <p>{{dataslearn.getParcours(parcours.choice).title}}</p>
        </button>
    </div>
    </div>
</template>
<script>
import { useLearnStore } from '@/stores/learn'
export default {
    name: 'LearnChoice',
    data() {
        return {
            dataslearn: useLearnStore(),
        }
    },
    // Mount vuejs
    created() {
        //let $ = window.$;
        //let that = this;
        //console.log("mounted List", this.dataslearn.datas.result_autodiagnv);

        /*for (let i in this.dataslearn.datas.parcours) {
            console.log(this.dataslearn.datas.parcours[i])
        }*/

        this.$root.showNav = false;
        this.$root.showMenu = false;

        if (this.lng !== 'fr' && this.lng !== 'es') {
            this.$root.$router.push("/"+this.$root.language+"/"+this.lng);
        } else {
            this.$root.language = this.lng
        }

    },
    methods: {
        setChoiceParcours: function(id) {
            this.dataslearn.validAutodiag ({select:id,lib:this.dataslearn.getParcours(id).title});
            this.$root.$router.push("/" + this.$root.language + "/learn");
        }
    },
    watch: {
        lng: function(newVal) {
            if (newVal !== 'fr' && newVal !== 'es') {
                this.$root.$router.push("/"+this.$root.language+"/"+newVal);
            } else {
                this.$root.language = this.lng
            }
        },
    },
    props: ['society','lng']
}
</script>
<style scoped>
h1 {
    font-size: var(--fs-700);
    font-weight: 700;
    color: var(--clr-blue);
    margin-bottom: 2em;
}

.mobile .container {
    padding: 1em 1em 1em 1em;
}



.list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
}

.mobile .list {
    grid-template-columns: 1fr;
}

button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    appearance: none;
    border: none;
    border-radius: 1em;
    overflow: hidden;
    align-content: center;
    align-items: center;
    background-color: var(--clr-white);
    filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.18));
}

button img {
    object-fit: cover;
    height: 10em;
}

button p {
    padding: 1em;
}

</style>