<template>
    <ScormPlayer :folder_url="'packages/'+$route.params.idscorm" :id="$route.params.idscorm" :start_callback="startScorm" :end_callback="endScorm"/>
</template>
<script>
import { useLearnStore } from '@/stores/learn'
import { kcAuthStore } from '@/stores/kcAuth'
import ScormPlayer from '@/components/ScormPlayer'

export default {
    name: "ScormTest",
    components: {
        ScormPlayer: ScormPlayer
    },
    watch: {
    },
    data() {
        return {
            dataslearn: useLearnStore(),
            auth: kcAuthStore(),
        }
    },
    methods: {
      startScorm: function() {
          console.log("--------------------------_> startScorm")
      },
      endScorm: function() {
          console.log("--------------------------_>endScorm")
      },
    },
    created() {
        let that = this;
        that.$root.showNav = false;
        that.$root.showMenu = false;
    },
    props: ['idscorm'],
}
</script>
<style scoped>
  .scormplayer {
    position: fixed;
    width: calc(100% - 18em);
    height: calc(100% - 5em);
    border-radius: 1em;
    overflow: hidden;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
  }

  .mobile .scormplayer {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
</style>