<template>
    <div v-if="!dataslearn.datas.version"> loading </div>

        <div class="plugcontainer" v-if="dataslearn.datas.version">
            <!--<button class="backparcours" v-if="!$isMobile()" @click="$root.$router.push('/l/' + idparcours)">{{parcours.title}}</button>-->
            <div class="tracks">
                <button class="back" @click="$root.$router.push('/' + this.$root.language +'/l/' + idparcours)" >{{$root.translation[$root.language].back}}</button>
                <div class="plugs">
                    <!--<span v-for="p in parcours.plugs" :key="p.id" class="plug" :class="[plug.completed?'completed':'']"></span>-->
                    <div class="bar" :style="{width: ((parcours.plugs.filter((obj) => obj.completed).length / parcours.plugs.length)*100)+'%'}"></div>
                </div>
            </div>
            <h1>{{plug.title}}</h1>
                <transition name="pop">
                    <div class="video switchpart" v-if="plug.video_url && currentPart == 'video'" @vnodeMounted="scrollto">
                        <div v-if="plug.video_alert">
                            <ul class="video_alert">
                                <li v-for="item in plug.video_alert" :key="item.icon"><img :src="dataslearn.datas.icons_url['video_alert/'+item.icon]">{{item.text}}</li>
                            </ul>
                        </div>
                        <VideoPlayer :video_url="plug.video_url" :poster_url="plug.image_url" :start_callback="videoplay" :end_callback="videoend" :disable_seek="!plug.completed && !plug.video_completed" :srt_url="plug.srt_url"/>
                        <button v-if="plug.completed || plug.video_completed" @click="next()" @vnodeMounted="scrollto" class="next">{{$root.translation[$root.language].next}}</button>
                    </div>
                </transition>

                <transition name="pop">
                    <div class="card switchpart" v-if="currentPart == 'card'" @vnodeMounted="scrollto">
                        <img v-if="!plug.bd" class="illustration" :src="plug.image_url">
                        <div v-if="plug.function" class="function block">
                            <h2>{{$root.translation[$root.language].function}}</h2>

                            <div><img :src="plug.function[0].icon_url">
                                <p>{{plug.function[0].text}}</p>
                            </div>
                        </div>
                        <div v-if="plug.securite_train" class="pictos securite_train block">
                            <h2>{{$root.translation[$root.language].securite_train}}</h2>
                            <ul>
                                <li v-for="item in plug.securite_train" :key="item.icon"><img :src="dataslearn.datas.icons_url['securite_train/'+item.icon]">{{item.text}}</li>
                            </ul>
                        </div>
                        <div v-if="plug.equipment" class="pictos equipment block">
                            <h2>{{$root.translation[$root.language].equipment}}</h2>
                            <ul>
                                <li v-for="item in plug.equipment" :key="item.icon"><img :src="dataslearn.datas.icons_url['equipment/'+item.icon]">{{item.text}}</li>
                            </ul>
                        </div>
                        <div v-if="plug.prohibitions" class="pictos prohibitions block">
                            <h2>{{$root.translation[$root.language].prohibitions}}</h2>
                            <ul>
                                <li v-for="item in plug.prohibitions" :key="item.icon">
                                    <div class="picto"><img :src="dataslearn.datas.icons_url['prohibitions/'+item.icon]"></div>{{item.text}}
                                </li>
                            </ul>
                        </div>
                        <div v-if="plug.composition" class="pictos composition block">
                            <h2>{{$root.translation[$root.language].composition}}</h2>
                            <ul>
                                <li v-for="item in plug.composition" :key="item.icon"><img :src="dataslearn.datas.icons_url['composition/'+item.icon]">{{item.text}}</li>
                            </ul>
                        </div>
                        <div v-if="plug.materiels" class="pictos materiels block">
                            <h2>{{$root.translation[$root.language].materiels}}</h2>
                            <ul>
                                <li v-for="item in plug.materiels" :key="item.icon"><img :src="dataslearn.datas.icons_url['materiels/'+item.icon]">{{item.text}}</li>
                            </ul>
                        </div>
                        <div v-if="plug.advices" class="cards advices block">
                            <h2>{{$root.translation[$root.language].advices}}</h2>
                            <ul>
                                <li v-for="item in plug.advices" :key="item.icon" :class="item.class">
                                    <div class="picto"><img :src="dataslearn.datas.icons_url['advices/'+item.icon]"></div>{{item.text}}
                                </li>
                            </ul>
                        </div>

                        <div v-if="plug.texts" class="texts">
                            <div v-for="item in plug.texts" :key="item" class="block" :style="'--bg-url:url('+item.icon_url+')'">

                                <div v-html="item.content"></div>
                                
                            </div>
                        </div>

                        <div v-if="plug.downloads" class="downloads block">
                            <div v-for="item in plug.downloads" :key="item">
                                <a :href="item.dl_url" target="_blank">{{item.title}}</a>
                                
                            </div>
                        </div>

                        <div v-if="plug.types" class="types">
                            <div v-for="type in plug.types" :key="type" class="block" :style="'--bg-url:url('+type.icon_url+')'">
                                <h2>{{type.title}}</h2>
                                <ol>
                                    <li v-for="item in type.steps" :key="item.icon"><img :src="item.image_url">
                                        <div v-html="item.text" class="deephtml"></div>
                                    </li>
                                </ol>
                            </div>
                        </div>

                        <div v-if="plug.steps" class="steps block">
                            <h2>{{$root.translation[$root.language].steps}}</h2>
                            <ol>
                                <li v-for="item in plug.steps" :key="item.icon"><img :src="item.image_url">
                                    <div v-html="item.text" class="deephtml"></div>
                                </li>
                            </ol>
                        </div>
                        <div v-if="plug.practices" class="block practices">
                            <h2>{{$root.translation[$root.language].practices}}</h2>
                            <ul>
                                <li v-for="item in plug.practices" :key="item.icon"><img :src="item.image_url">{{item.text}}</li>
                            </ul>
                        </div>
                        <div v-if="plug.critere_qualite" class="critere_qualite block">
                            <h2>{{$root.translation[$root.language].critere_qualite}}</h2>
                            <ul>
                                <li v-for="item in plug.critere_qualite" :key="item.icon"><img :src="item.image_url">{{item.text}}</li>
                            </ul>
                        </div>
                        <div v-if="plug.gammes" class="gammes block">
                            <h2>{{$root.translation[$root.language].gammes}}</h2>
                            <ul>
                                <li v-for="item in plug.gammes" :key="item.icon"><img :src="item.image_url">{{item.text}}</li>
                            </ul>
                        </div>
                        <div v-if="plug.bd" class="bd">
                            <div class="sounded"><button @click="playBdSounds(plug.bd)"><img src="@/assets/css/img/icon_sound.svg"></button></div>
                            <ul>
                                <li v-for="item in plug.bd" :key="item.icon" :class="[item.sound_url == currentSound ? 'currentsound' : '']"><img :src="item.image_url">{{item.text}}</li>
                            </ul>
                        </div>
                        <button @click="next()" class="next">{{$root.translation[$root.language].next}}</button>
                    </div>
                </transition>

                <transition name="pop">
                    <div class="bonus switchpart" v-if="currentPart=='bonus'" @vnodeMounted="scrollto">
                        <div v-if="plug.dpa" class="dpa">
                            <h2>{{$root.translation[$root.language].dpa}}</h2>
                            <ul>
                                <li v-for="item in plug.dpa" :key="item.icon"><img :src="item.image_url">{{item.text}}</li>
                            </ul>
                        </div>
                        <div v-if="plug.videob">
                            <h2>{{$root.translation[$root.language].dpa}}</h2>
                            <ul>
                                <li v-for="item in plug.dpa" :key="item.icon">{{item.text}}<video controls>
                                        <source :src="item.url" type="video/mp4" /></video></li>
                            </ul>
                        </div>
                        <button @click="next()" class="next">{{$root.translation[$root.language].next}}</button>
                    </div>
                </transition>

                <transition name="pop">
                    <div class="quiz switchpart" v-if="plug.quiz && currentPart == 'quiz'" @vnodeMounted="scrollto">
                        <template v-for="(question, count) in plug.questions" :key="count">
                            <div v-if="count <= currentQ" @vnodeMounted="scrollto">
                                <div class="sounded"><button @click="playQuestionSounds(question)"><img src="@/assets/css/img/icon_sound.svg"></button></div>
                                <h2 :class="[question.question_sound_url == currentSound ? 'currentsound' : '']">{{question.question}}</h2>
                                <div>
                                    
                                    <form @submit="validQuiz" :id="count">
                                        <div class="answers" :class="question.type">
                                            <label v-for="(answer, index) in question.answers" :key="index" :for="plug.id + '_' + count + '_' + index" :class="[question.correct ? answer.answer : '', answer.sound_url == currentSound ? 'currentsound' : '']">
                                                <img :src="answer.background_url">
                                                <input :type="question.answers.length>2?'checkbox':'radio'" name="quiz" :id="plug.id + '_' + count + '_' + index" :value="answer" v-model="question.selected" :disabled="question.correct">
                                                <p>{{answer.text}}</p>
                                            </label>
                                        </div>
                                        <button v-if="!question.correct" :disabled="question.selected.length == 0" class="next">{{$root.translation[$root.language].validate}}</button>
                                    </form>
                                </div>
                                <div v-if="question.correct" class="debrief" @vnodeMounted="scrollto">
                                    <h3 v-if="(question.answers.length<=2 && question.selected.answer == 'good')||(question.answers.length>2 && question.selected.length == question.answers.filter((obj) => obj.answer === 'good').length && question.selected.filter((obj) => obj.answer === 'good').length == question.answers.filter((obj) => obj.answer === 'good').length)" class="debriefgood">{{$root.translation[$root.language].good_answer}}</h3>
                                    <h3 v-else class="debriefbad">{{$root.translation[$root.language].bad_answer}}</h3>
                                    <div class="content deephtml" :class="[question.debrief_sound_url == currentSound ? 'currentsound' : '']"  v-html="question.debrief"></div>
                                    <img v-for="bg in question.debrief_backgrounds_url" :key="bg" :src="bg">
                                    <button @click="nextQuestion()" v-if="currentQ == count || count >= (plug.questions.length - 1)" class="next">{{$root.translation[$root.language].next}}</button>
                                </div>
                            </div>
                        </template>
                    </div>
                </transition>
        </div>
</template>
<script>
import { useLearnStore } from '@/stores/learn';
import { kcAuthStore } from '@/stores/kcAuth';
import VideoPlayer from '@/components/VideoPlayer'

export default {
    name: 'LearnPlugPart',
    components: {
      VideoPlayer: VideoPlayer
    },
    data() {
        return {
            dataslearn: useLearnStore(),
            auth: kcAuthStore(),
            plug: {},
            parcours: {},
            currentQ: 0,
            currentPart: '',
            sounds_cue: Audio,
            currentSound: '',
            catcherrorvideo: false,
            chrono: 0,
            videoBeingPlayed: false,
            timeElapsed: null,
            percentagePlayed: 0,
            timeRemaining: '00:00',
            bufferRanges: [],
        }
    },
    methods: {
        scrollto: function(e) {
            setTimeout(function() {
                e.el.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
            }, 100)
        },
        validQuiz: function(e) {
            console.log("validQuiz", e.target.id,  this.plug.questions[e.target.id]);


            let q = this.plug.questions[e.target.id]

            q.correct = true;


            let that = this;

            if (q.isplaysound) {
                this.sounds_cue.pause();
                this.sounds_cue = new Audio(q.debrief_sound_url);
                this.currentSound = q.debrief_sound_url;
                this.sounds_cue.play();
                q.isplaysound = false;

                this.sounds_cue.addEventListener('ended', function() {
                    that.currentSound = '';
                });
            }

            e.preventDefault();

        },
        videoplay() {
            this.dataslearn.startVideo(this.plug);
        },
        videoend() {
            this.dataslearn.completeVideo(this.plug);
        },
        playQuestionSounds(q) {
            q.playdeb = true;
            q.isplaysound = true;
            let i = 0;
            let that = this;

            this.sounds_cue.pause();
            this.sounds_cue = new Audio(q.question_sound_url);
            this.currentSound = q.question_sound_url;

            this.sounds_cue.addEventListener('ended', function() {

                if (i < q.answers.length) {
                    that.sounds_cue.src = q.answers[i].sound_url;
                    that.currentSound = q.answers[i].sound_url;
                    that.sounds_cue.play();
                    i++;
                } else {
                    if (q.correct) {
                        if (q.playdeb) {

                            that.sounds_cue.src = q.debrief_sound_url;
                            that.currentSound = q.debrief_sound_url;
                            that.sounds_cue.play();

                            q.playdeb = false;
                        } else {
                            that.currentSound = '';
                        }
                    } else {
                        that.currentSound = '';
                    }
                }



            }, false);

            this.sounds_cue.play();
        },
        playBdSounds(bd) {

            let i = 0;
            let that = this;

            this.sounds_cue.pause();
            this.sounds_cue = new Audio(bd[i].sound_url);
            this.currentSound = bd[i].sound_url;

            this.sounds_cue.addEventListener('ended', function() {
                console.log(i)
                i++;
                if (i < bd.length) {
                    that.sounds_cue.src = bd[i].sound_url;
                    that.currentSound = bd[i].sound_url;
                    that.sounds_cue.play();
                    
                } else {
                    that.currentSound = '';
                }


            }, false);

            this.sounds_cue.play();
        },

        getNextPart() {
            for (let i = 0; i < this.plug.orderpart.length; i++) {
                if (this.plug.orderpart[i] == this.currentPart) return this.plug.orderpart[i + 1]
            }
        },
        getNextPlug() {
            for (let i = 0; i < this.parcours.plugs.length; i++) {
                if (this.parcours.plugs[i].id == this.idplug) return this.parcours.plugs[i + 1]?.id;
            }
        },
        next() {
            //this.getNextPart() ? this.$root.$router.push('/l/' + this.idparcours + "/p/" + this.idplug + "/" + this.getNextPart()) : this.getNextPlug() ? this.$root.$router.push('/l/' + this.idparcours + "/p/" + this.getNextPlug()) : this.$root.$router.push('/l/' + this.idparcours)
            
            if (this.getNextPart()){
                //part suivante
                this.$root.$router.push('/' + this.$root.language + '/l/' + this.idparcours + "/p/" + this.idplug + "/" + this.getNextPart())
            } else {
                this.dataslearn.completePlug(this.plug);

                if (this.parcours.plugs.filter(p => p.completed).length == this.parcours.plugs.length) this.dataslearn.completeParcours(this.parcours);

                if (this.getNextPlug()) {
                    // fiche suivante
                    this.$root.$router.push('/' + this.$root.language + '/l/' + this.idparcours + "/p/" + this.getNextPlug())
                } else {
                    // fin de parcours
                    this.$root.$router.push('/' + this.$root.language + '/l/' + this.idparcours + "/reward")
                }
            }  
        },
        nextQuestion() {

            if (this.currentQ < (this.plug.questions.length - 1)) {
                this.currentQ++;
            } else {

                let passed = true;

                for (let i in this.plug.questions) {
                    let q = this.plug.questions[i];
                    if (q.selected.length != q.answers.filter((obj) => obj.answer === 'good').length || q.selected.filter((obj) => obj.answer === 'good').length != q.answers.filter((obj) => obj.answer === 'good').length) {
                        passed = false;
                    }
                }
                console.log("uuuuu",passed)
                this.plug.quiz.passed = passed;

                this.dataslearn.validQuiz(this.plug, this.parcours.id);

                this.next()
            }
        },
        initPlug() {

            

            // get elements by url ids
            this.parcours = this.dataslearn.getParcours(this.idparcours);
            this.plug = this.dataslearn.getPlug(this.idplug);

            console.log("initPlug this is the plug",this.plug)
            console.log("iconsset", this.dataslearn.datas.icons_url)

            //prevent unauthorized direct access
            let index = this.parcours.plugs.findIndex(p => p.id === this.plug.id)
            if (index > 0 && !this.parcours.plugs[(index-1)].completed) return this.$root.$router.push("/" + this.$root.language + "/l/"+this.parcours.id)

            // initalize 
            this.chrono = new Date().getTime();
            this.dataslearn.startParcours(this.parcours);
            this.dataslearn.viewPlug(this.plug);
            this.dataslearn.setCurrentPlug(this.parcours, this.parcours.plugs.findIndex(p => p.id === this.plug.id));


            //
            if (this.plug.quiz) {
                this.plug.questions = [];
                let questions = this.plug.quiz[this.parcours.id] || this.plug.quiz['default'];

                let count = 1;
                while (questions[count]) {
                    questions[count].selected = [];
                    questions[count].correct = false;

                    this.plug.questions.push(questions[count]);
                    count++;
                }

                this.currentQ = 0;
            }

            if (!this.part || this.part.length == 0) {
                //this.$root.$router.push('/l/' + this.idparcours + '/p/' + this.idplug + '/' + this.plug.orderpart[0])
                //this.$root.$router.push('/l/' + this.idparcours + '/p/' + this.idplug + '/' + this.plug.orderpart[0])
                this.currentPart = this.plug.orderpart[0];
            } else {
                this.currentPart = this.part;
            }

            //initialize audio
            this.sounds_cue = new Audio();
           

            
        }
    },
    props: ['idparcours', 'idplug', 'part', 'lng'],
    watch: {
        part: function(newVal) {
            newVal.length == 0 ? newVal = this.plug.orderpart[0] : newVal;
            this.currentPart = newVal;
        },
        idplug: function() {

            let session_time = new Date().getTime() - this.chrono;
            this.dataslearn.settimePlug (this.plug, session_time);

            this.initPlug();
        },
        currentSound(newVal) {
            if (newVal != '')
                setTimeout(function() {
                    if (window.$(".currentsound").get(0)) window.$(".currentsound").get(0).scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
                }, 10)

            //
        },
        lng: function(newVal) {
            if (newVal !== 'fr' && newVal !== 'es') {
                this.$root.$router.push("/"+this.$root.language+"/"+newVal);
            } else {
                this.$root.language = this.lng
            }
        }
    },
    unmounted() {
        let session_time = new Date().getTime() - this.chrono;
        this.dataslearn.settimePlug (this.plug, session_time);
    },
    created() {
        this.$root.showNav = false;
        this.$root.showMenu = false;
        this.initPlug();

        if (this.lng !== 'fr' && this.lng !== 'es') {
            this.$root.$router.push("/"+this.$root.language+"/"+this.lng);
        } else {
            this.$root.language = this.lng;
        }
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    font-size: var(--fs-700);
    font-weight: 700;
    color: var(--clr-blue);
    margin-bottom: 1em;
}

h2 {
    margin-block: 1em;
    font-size: var(--fs-600);
    font-weight: 700;
}

.video_alert {
    display: grid;
    grid-template-columns: 1fr;
    width: min(100%, 34em);
    margin-inline: auto;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1em;
    font-weight: bold;
    margin-bottom: 1em; 
}

.video_alert li {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    border-radius: 1em;
    border: 2px var(--clr-blue) solid;
    background-color: var(--clr-background-default);
}

.video_alert li img {
    display: inline;
}

video {
    display: block;
    margin-inline: auto;
    width: min(100%, 40em);
    aspect-ratio: 16/9;
    border-radius: 1em;
}

.mobile video {
    /*width: calc(100% - 2em);*/
}

video[poster] {
    object-fit: cover;
}

li {
    list-style: none;
}

input {
    appearance: none;
}

.backparcours {
    appearance: none;
    border: none;
    position: fixed;
    left: 12em;
    top: 1em;
    height: 2em;
    display: block;
    background: none;
    padding-left: 3em;
    cursor: pointer;
}

.backparcours:after {
    content:'';
    position: absolute;
    width: 2em;
    height: 2em;
    top: calc(50% - 1em);
    left: 0;
    border-radius: 50%;
    background-image: url(@/assets/css/img/icon_arrow_white.svg),linear-gradient(180deg, #023570 0%, #017DAC 100%);
    background-size: 50% 50%, 100% 100%;
    background-position: center, center;
    background-repeat: no-repeat, no-repeat;
}

.tracks {
    width: calc(50% - 8rem);
    background-color: var(--clr-white);
    border-radius: 100vw;
    display: flex;
    overflow: hidden;
    margin-bottom: 2em;
    position: fixed;
    top: 1em;
    z-index: 1;
    box-shadow: 0px 3px 6px rgba(2, 53, 112, 0.14);
}

.mobile .tracks {
    width: calc(100% - 2em);
    background-color: var(--clr-white);
    border-radius: .5em;
    display: flex;
}

.tracks .back {
    appearance: none;
    border: none;
    background-image: url(@/assets/css/img/icon_arrow_white.svg),linear-gradient(180deg, #023570 0%, #017DAC 100%);
    background-size: 50% 50%, 100% 100%;
    background-position: center, center;
    background-repeat: no-repeat, no-repeat;
    color: transparent;
    width: 3em;
    cursor: pointer;
}

.tracks .plugs {
    width: calc(100% - 5em);
    /*display: grid;
    grid-auto-flow: column;
    justify-content: space-between;*/
    height: .5em;
    border-radius: 100vw;
    background-color: var(--clr-grey-light);
    margin-left: 1em;
    margin-block: .8em;
}

.tracks .plugs .bar {
    height: 100%;
    background-color: var(--clr-blue);
    border-radius: 100vw;
    transition: all 250ms;
}

/*.tracks .plug {
    display: block;
    width: 1.2em;
    height: 1.2em;
    background-color: var(--clr-grey);
    align-self: center;
    border-radius: 50%;
}

.tracks .plug.completed {
    background-image: url(@/assets/css/img/icon_check_white.svg),linear-gradient(180deg, #023570 0%, #017DAC 100%);
    background-size: 60% 60%, 100% 100%;
    background-position: center, center;
    background-repeat: no-repeat, no-repeat;

}*/

.plugcontainer {
    min-height: 100%;
}

.mobile .plugcontainer {
    padding-top: 6em;
    padding-inline: 1em;
}

.block {
    background-color: var(--clr-white);
    border-radius: 1em;
    padding: 1em;
    margin-block: 3em;
    border: solid 1px var(--clr-blue);
}

.block {
    position: relative;
}

.block:after {
    content: '';
    position: absolute;
    background-color: var(--clr-background-default);
    width: 4em;
    height: 4em;
    border-radius: 50%;
    top: -2em;
    left: calc(50% - 1.5em);
    background-color: var(--clr-blue);
    background-image: var(--bg-url);
    background-size: 70% 70%;
    background-repeat: no-repeat;
    background-position: center center;
}

.block.prohibitions {
    border-color: var(--clr-bad);
}

.block.prohibitions:after {
    background-color: var(--clr-bad);
}

.block.gammes {
    --bg-url: url(@/assets/css/img/icon_gammes.svg);
}

.block.prohibitions {
    --bg-url: url(@/assets/css/img/icon_prohibition_pale.svg);
}

.block.materiels {
    --bg-url: url(@/assets/css/img/icon_materials.svg);
}

.block.advices {
    --bg-url: url(@/assets/css/img/icon_advices.svg);
}

.block.steps {
    --bg-url: url(@/assets/css/img/icon_steps.svg);
}

.block.practices {
    --bg-url: url(@/assets/css/img/icon_steps.svg);
}

.block.function {
    --bg-url: url(@/assets/css/img/icon_function.svg);
}

.block.gammes {
    --bg-url: url(@/assets/css/img/icon_gammes.svg);
}

.block.equipment {
    --bg-url: url(@/assets/css/img/icon_equipment.svg);
}

.block.downloads {
    --bg-url: url(@/assets/css/img/icon_download.svg);
}




.video {
    min-height: 100%;
}

.illustration {
    aspect-ratio: 26/9;
    object-fit: cover;
    position: relative;
    width: 100%;
    border-radius: 1em;
}

.pictos ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
}

.pictos li {
    flex: 0 0 5em;
    text-align: center;
}

.pictos.securite_train img {
    background-color: var(--clr-blue);
    border-radius: 50%;
    padding: .5em;
    margin-inline: auto;
}

.pictos.equipment img {
    background-color: var(--clr-blue);
    border-radius: 50%;
    padding: .5em;
    margin-inline: auto;
}

.pictos.composition img {
    background-color: var(--clr-white);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
    border-radius: 50%;
    padding: .5em;
    margin-inline: auto;
    margin-bottom: .5em;
}

.pictos.prohibitions .picto {
    padding: .5em;
    position: relative;
}

.pictos.prohibitions .picto img {
    margin-inline: auto;
}

.pictos.prohibitions .picto:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(@/assets/css/img/icon_prohibition.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

.cards ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
}

.cards li {
    flex: 0 1 12em;
    text-align: center;
    aspect-ratio: 4/5;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 4.5em 1em 1em 1em;
}

.cards li img {
    margin-inline: auto;
}

.cards li .picto {

    margin-bottom: .5em;
}

.cards li.do {
    background-image: url(@/assets/css/img/do.svg);
}

.cards li.do .picto img {
    border: solid .4em var(--clr-blue);
    border-radius: 50%;
}

.cards li.dont {
    background-image: url(@/assets/css/img/dont.svg);
}

.cards li.dont .picto img {
    border: solid .4em transparent;
}

.cards li.dont .picto {
    background-image: url(@/assets/css/img/icon_prohibition.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}

:is(.practices, .steps, .critere_qualite, .gammes, .bd, .types) img {
    width: min(30em, 50%);
    border-radius: 1em;
}

.mobile :is(.practices, .steps, .types) img {
    width: 100%;
}

.mobile .bd img {
    width: 100%;
}

:is(.practices, .steps, .critere_qualite, .gammes, .bd, .types) li {
    display: flex;
    gap: 3em;
    align-content: center;
    align-items: center;
    position: relative;
    counter-increment: count 1;
}

.mobile :is(.steps, .types) li {
    flex-direction: column;
}

.mobile .bd li {
    flex-direction: column;
}

:is(.practices, .steps, .critere_qualite, .gammes, .bd, .types) li.currentsound {
    font-weight: bold;
}

:is(.practices, .steps, .critere_qualite, .gammes, .bd, .types) :is(ol, ul) {
    display: flex;
    flex-direction: column;
    gap: 2em;
    counter-reset: count 0;
}

.downloads {
    text-align: left;
    padding-block: 3em;
    padding-inline: 3em;
}


.texts {
    text-align: left;
}

.texts > div {
    padding-block: 3em;
    padding-inline: 3em;
}

.texts :deep(b) {
    font-weight: 700;
}

.texts :deep(p) {
    margin-block: 1em;
}

.texts :deep(ul) {
    margin-left: 2em;
    margin-block: 1em;
}

.texts :deep(ol) {
    margin-left: 2em;
    margin-block: 1em;
}

:is(.practices, .steps) li:after {
    content: counter(count, decimal);

    position: absolute;
    background-color: var(--clr-blue);
    width: 2em;
    height: 2em;
    color: var(--clr-white);
    line-height: 2em;
    border-radius: 50%;
    font-weight: 700;
    top: calc(50% - 1em);
    left: calc(min(50%, 30em) + .5em);
}

.mobile :is(.practices, .steps) li:after {
    top: 1em;
    left: 1em;
}



.function div {
    display: flex;
    gap: 1em;
    align-content: center;
    align-items: center;
    position: relative;
}

.function img {
    width: min(30em, 50%);
    border-radius: 1em;
}

.quiz {
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    gap: 4em;
}

.quiz h2 {
    scale: 1;
    transition: all 350ms;
}

.quiz h2.currentsound {
    font-weight: 700;
    scale: 1.1;
}

.quiz .content {
    scale: 1;
    transition: all 350ms;
    margin-bottom: 1em;
}

.quiz .content :deep(ul) {
    text-align: left;
    margin-inline: 3em;
    margin-block: 1em;
}

.quiz .content.currentsound {
    font-weight: 700;
    scale: 1.05;
}

.quiz .answers {
    display: flex;
    gap: 1em;
    justify-content: center;

    flex-wrap: wrap;
}

.quiz label {
    flex: 0 0 15em;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    background-color: var(--clr-white);
    padding-bottom: 1em;
    scale: 1;
    transition: all 250ms;
}

.mobile .quiz label {
    flex: 0 0 40%;
}

.quiz label.currentsound {
    scale: 1.1;
}

.quiz .visual label {
    flex: 0 0 25em;
}

.quiz .list label {
    flex: 0 0 100%;
    flex-direction: row;
    padding: 0;
}

.quiz .list label img {
    width: 40%;
}

.quiz label:after {
    position: absolute;
    right: .5em;
    bottom: .5em;
    width: 1em;
    height: 1em;
    background-image: url(@/assets/css/img/icon_thumbup.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.quiz label.good {
    background-color: var(--clr-good);
    color: var(--clr-white);
}

.quiz label.good:after {
    content: '';
}

.quiz label.bad {
    background-color: var(--clr-bad);
    color: var(--clr-white);
}

.quiz label.bad:after {
    content: '';
    rotate: 180deg;
}

.quiz label input {
    position: relative;
    width: 3em;
    height: 3em;
    z-index: 1;
    left: calc(50% - 1.5em);
    top: -1.5em;
    background-image: url(@/assets/css/img/icon_checkbox_grey.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.quiz .list label input {
    position: absolute;
    flex: 0 0 3em;
    width: 3em;
    height: 3em;
    left: calc(40% - 1.5em);
    top: calc(50% - 1.5em);
}

.quiz label input:checked {
    background-image: url(@/assets/css/img/icon_checkbox_blue.svg);
}

.quiz label p {
    position: relative;
    margin-inline: .5em;
    top: -1em;
}

.quiz .list label p {
    top: 0;
    text-align: left;
    padding: 1em;
}

.quiz .debrief {
    margin-top: 2em;
}

.quiz .debrief h3 {
    font-size: var(--fs-600);
    font-weight: 700;
    margin-block: 2em;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.quiz .debrief h3.debriefbad {
    color: var(--clr-bad);
    background-image: url(@/assets/css/img/icon_thumb_bad.svg);
}

.quiz .debrief h3.debriefgood {
    color: var(--clr-good);
    background-image: url(@/assets/css/img/icon_thumb_good.svg);
}

.quiz .debrief p {
    margin-block: 1em;
}

.quiz .debrief p.content {
    margin-block: 1em;
}

.quiz .debrief img {
    aspect-ratio: 26/9;
    object-fit: cover;
    position: relative;
    width: 100%;
    border-radius: 1em;
}

.sounded {
    position: sticky;
    top: 5em;
    right: 0;
    z-index: 1;
}

.sounded button {
    position: absolute;
    right: -2em;
    top: -1em;
    background-color: var(--clr-white);
    padding: .5em 1em .5em .5em;
    border: none;
    border-radius: 100vw 0 0 100vw;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.18);
    cursor: pointer;
}

.mobile .sounded button {
    right: -1em;
}

.sounded button img {
    width: 2em;
    height: 2em;
}



button.next {
    margin-block: 2em;
    padding: 1em 4em;
    border: none;
    border-radius: 100vw;
    background-image: linear-gradient(180deg, #0565A9 0%, #023570 100%);
    box-shadow: 0 4px 16px 0 rgb(2 53 112 / 40%);
    color: #fff;
    text-align: center;
    font-weight: 700;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
}

button.next:disabled {
    opacity: .5;
    cursor: default;
}

.deephtml {
    width: 100%;
}

.deephtml :deep(b) {
    font-weight: 700;
}

.deephtml :deep(ol) {
    text-align: left;
    margin-block: 1em;
    margin-inline-start: 2em;
}

.deephtml :deep(ul) {
    text-align: left;
    margin-block: 1em;
    margin-inline-start: 2em;
    display: inline-block;
}

    @keyframes viewJsSpinner {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .pop-enter-active,
    .pop-leave-active {
        transition: all 350ms;
    }

    .pop-enter-from {
      opacity: 0;
      scale: .9;
    }

    .pop-enter-to {
      opacity: 1;
      scale: 1;
    }

    .pop-leave-from {
        display: none;
        opacity: 1;
        scale: 1;
    }

    .pop-leave-to {
        display: none;
        opacity: 0;
        scale: .9;
    }

</style>