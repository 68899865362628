<template>
    
    <div v-if="!auth.prod" style="position: absolute; width: 100%">Attention l'application est connectée en preprod ({{language}})</div>
    <transition name="fromup">
      <header v-if="($isMobile() && showNav) || fullscreen" class="mobile">
          <button v-if="!fullscreen" class="burger" aria-label="menu" :aria-pressed="showMenu" @click="showMenu = !showMenu"><span></span><span></span><span></span><span></span></button>
          <img src="@/assets/img/onet_learn_inline.svg" class="logo">
      </header>
    </transition>
    <nav v-if="(!$isMobile() || ($isMobile() && showMenu)) && !fullscreen" :class="{mobile:$isMobile()}">
        <img v-if="!$isMobile()" src="@/assets/img/onet_learn.svg" class="logo">
        <div v-if="!$isMobile()" class="separator"></div>
        <router-link :to="{ path: '/' + language }" class="home">{{translation[language].accueil}}</router-link>

        <router-link :to="{ path: '/' + language + '/firststeps'}" class="firststeps">{{translation[language].mpp}}</router-link>
        <div class="separator"></div>

        <router-link :to="{ path: '/' + language + '/learn'}" class="learn" :class="[auth.isLoggedIn?'':'locked']">{{translation[language].learn}}</router-link>
        <router-link :to="{ path: '/' + language + '/modop'}" class="modop" v-if='language == "fr"'>{{translation[language].modop}}</router-link>
        <!--<router-link to="/event" class="event">Onet Evénementiel</router-link>-->
    </nav>

    <transition name="fromup">
      <div v-if="($isMobile() && showNav || !$isMobile()) && !fullscreen" class="login" :class="$isMobile()?'mobile':''" >
          <router-link :to="{ path: '/' + language + '/user'}" v-if="auth.isLoggedIn"><span v-if="!$isMobile()">{{auth.user.infos.firstname}} {{auth.user.infos.lastname}}</span> <img src="@/assets/css/img/user.svg"></router-link>
          <router-link :to="{ path: '/' + language + '/login'}" v-if="!auth.isLoggedIn" class="notconnected">{{translation[language].connect}}</router-link>
      </div>
      </transition>
    <div class="main" :class="[$isMobile()?'mobile':'', showMenu?'withmenu':'', fullscreen?'fullscreen':'']">
      <router-view/>
    </div>
    <!--<router-view v-slot="{ Component }">
        <transition name="fade">
            <component :is="Component" class="main" :class="$isMobile()?'mobile':''"/>
        </transition>
    </router-view>-->
</template>
<script>
import { kcAuthStore } from '@/stores/kcAuth'

export default {
    name: 'App',
    mounted() {
        this.auth.apikey = "eZACEGY6PVcP2MplwQU9wFkTSiZcU8EG";
        this.auth.useragent = window.navigator.userAgent;
        this.$router.beforeEach((to) => {
            if (to.path.substr(1,2) != 'fr' && to.path.substr(1,2) != 'es') {
                return {path:'/fr'+to.path}
            }
            if (to.meta.fullScreen) {
                this.$root.fullscreen = true;
            } else {
                this.$root.fullscreen = false;
            }
            if (to.meta.requiresAuth && !this.auth.isLoggedIn) {
                // this route requires auth, check if logged in
                // if not, redirect to login page.
                return {
                    path: '/'+this.language+'/login',
                    // save the location we were at to come back later
                    query: { redirect: to.fullPath },
                }
            }
        });

        ((t,m)=>{(m[atob('bGVhcm5lcl9pZA==')]+''+m[atob('YXV0aF9jb21wYW55X3Rva2Vu')]).split('').map(e=>t+=e.charCodeAt()*Math.PI*42);m[atob('dG9rZW5fa2V5')]=btoa(t)})(0,this);


        this.user_random_id = document.cookie.split(';').map(e => e.trim().split('=')).filter(e => e[0] === 'page_user_tracking')[0];

        // S'il n'existe pas on créer un cookie
        if(!this.user_random_id)
        {
            // Création d'un identifiant unique pour le navigateur courrant
            this.user_random_id = [0, Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2)];

            // On enregistrer dans un cookie
            document.cookie = 'page_user_tracking=' + this.user_random_id + '; expires="' + (new Date(+new Date()+1000*60*60*24*365)).toUTCString() + '"; path=/';
        }

        // On récupère la valeure du cookie ou du user_random_id
        this.user_random_id = this.user_random_id[1];







    },
    data() {
        return {
            user: {},
            loading: { connect: false },
            post: null,
            error: null,
            showNav: true,
            showMenu: false,
            fullscreen: false,
            learner_id: 'pages_trackings',
            auth_company_token: '59cb687a4029fe1cbe1a72e7.eZACEGY6PVcP2MplwQU9wFkTSiZcU8EG.3d4f9bd20619c1716f4263986fbc92fb1ef2ae6121ee222f14a1e5d3cf500b24323880453983662259c1738576232c1930a2d0ee9314ed135adb82abae16d712',
            user_random_id: null,
            auth: kcAuthStore(),
            language: 'fr',
            translation : {
                'fr' : {
                    'accueil' : 'Accueil',
                    'mpp' : 'Mes premiers pas chez ONET',
                    'event_fundamentals' : 'Événementiel – Fondamentaux',
                    'learn' : 'Formations',
                    'modop' : 'Modes opératoires propreté',
                    'connect' : 'se connecter',
                    'disconnect' : 'se déconnecter',
                    'accueil_title' : 'Découvrez Onet Learn',
                    'mpp_content' : 'Ce que vous devez savoir en entrant chez Onet',
                    'learn_content' : 'Retrouvez les formations sur les gestes métiers Onet',
                    'modop_content' : 'Téléchargez les modes opératoires Onet',
                    'learn_choice' : 'Par quel parcours commencer ?',
                    'loading' : 'Merci de patienter pendant le chargement...',
                    'questions' : ' questions',
                    'my_journey' : 'Mes parcours de formation',
                    'all' : 'tous',
                    'discover' : 'À découvrir',
                    'progress' : 'En cours',
                    'finish' : 'Terminés',
                    'start_journey' : 'Commencer mon parcours',
                    'resume_journey' : 'Reprendre mon parcours',
                    'my_certificate' : 'Mon certificat',
                    'complete_certificate' : 'Terminer votre formation pour déverrouiller votre certificat',
                    'your_journey' : 'Votre parcours',
                    'back' : 'Retour',
                    'next' : 'Suivant',
                    'function' : 'Fonction',
                    'securite_train' : 'Sécurité en milieu ferroviaire',
                    'equipment' : 'Équipement',
                    'prohibitions' : 'Interdictions',
                    'composition' : 'Composition',
                    'materiels' : 'Matériel',
                    'advices' : 'Conseils',
                    'steps' : 'Étapes',
                    'practices' : 'Bonnes Pratiques',
                    'criteres_qualite' : 'Critères',
                    'gammes' : 'Gamme',
                    'dpa' : 'Détecter Protéger Alerter',
                    'validate' : 'Valider',
                    'good_answer' : 'Bonne réponse',
                    'bad_answer' : 'Mauvaise réponse',
                    'change_journey' : 'Changer le parcours',
                    'general' : 'Général',
                    'confidentiality' : 'Confidentialité',
                    'bravo' : 'Bravo !!',
                    'parcours_success' : 'Vous avez réussi votre parcours de formation',
                    'parcours_download' : 'vous pouvez visualiser votre attestation',
                    'skip' : "Passer",

                },
                'es' : {
                    'accueil' : 'Inicio',
                    'mpp' : 'Mis primeros pasos en ONET',
                    'learn' : 'Formación',
                    'modop' : 'Procedimientos operativos de limpieza',
                    'connect' : 'Conectarse',
                    'disconnect' : 'Disconectarse',
                    'accueil_title' : 'Descubre Onet Learn',
                    'mpp_content' : 'Lo que necesitas saber al unirte a Onet',
                    'learn_content' : 'Encuentra formación sobre acciones profesionales de Onet',
                    'modop_content' : 'Descarga los modos de funcionamiento de Onet',
                    'learn_choice' : '¿Por qué ruta empezar?',
                    'loading' : 'Espere mientras carga...',
                    'questions' : ' preguntas',
                    'my_journey' : 'Mis cursos de formación',
                    'all' : 'Todo',
                    'discover' : 'A descubrir',
                    'progress' : 'En curso',
                    'finish' : 'Terminado',
                    'start_journey' : 'Comenzar mi curso',
                    'resume_journey' : 'Reanudar mi curso',
                    'my_certificate' : 'Mi certificado',
                    'complete_certificate' : 'Completa tu formación para desbloquear tu certificado',
                    'your_journey' : 'Tu curso',
                    'back' : 'Atrás',
                    'next' : 'Siguiente',
                    'function' : 'Función',
                    'securite_train' : 'Seguridad en el entorno ferroviario',
                    'equipment' : 'Equipamiento',
                    'prohibitions' : 'Prohibiciones',
                    'composition' : 'Composición',
                    'materiels' : 'Material',
                    'advices' : 'Consejos',
                    'steps' : 'Pasos',
                    'practices' : 'Buenas prácticas',
                    'criteres_qualite' : 'Criterios',
                    'gammes' : 'Rango',
                    'dpa' : 'Detectar Proteger Alertar',
                    'validate' : 'Validar',
                    'good_answer' : 'Buena respuesta',
                    'bad_answer' : 'Respuesta incorrecta',
                    'change_journey' : 'Cambiar el curso',
                    'general' : 'General',
                    'confidentiality' : 'Confidencialidad',
                    'bravo' : '¡Bravo!',
                    'parcours_success' : 'Has completado con éxito el curso ',
                    'parcours_download' : 'A continuación, puedes obtener tu certificado',
                    'skip' : "Saltar",
                }
            }
        }
    },
    components: {},
    methods: {
        getPermission: function(id) {
            console.log(id);
        },
        addPageVisit: function (page) {

            let url = "https://api.korda.cloud/v1/track.add"

            if (!this.auth.prod) url = "https://preprod.korda.cloud/v1/track.add"

            const xhr = new XMLHttpRequest();
            xhr.open("POST", url );
            xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8")

            const body = JSON.stringify({
                auth_company_token: this.auth_company_token,
                learner_id:         this.learner_id,
                token_key:          this.token_key,
                type:               'page_user_tracking:'+page+':'+this.language+':'+this.auth.isLoggedIn,
                key:                this.user_random_id,
                value:              ''
            });

            xhr.onload = () => {

              if (xhr.readyState == 4 && xhr.status == 200) {
                console.log(JSON.parse(xhr.responseText));
              } else {
                console.log(`Error: ${xhr.status}`);
              }

            };

            xhr.send(body);
        }
    }
}
</script>
<style>
    
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --clr-default: #1C1C1C;
    --clr-blue: #023570;
    --clr-blue-light: #017DAC;
    --clr-blue-xlight: #B2D8E6;
    --clr-background-default: #F2F4FB;
    --clr-white: #FFFFFF;
    --clr-grey: #B6B6B6;
    --clr-grey-light: #F2F4FB;
    --clr-good: #3BB792;
    --clr-bad: #B92555;

    --fs-100: .6875rem;
    --fs-200: .75rem;
    --fs-300: .8125rem;
    --fs-400: .875rem;
    --fs-500: 1rem;
    --fs-600: 1.25rem;
    --fs-700: 1.525rem;
    --fs-800: 1.875rem;
    --fs-900: 2.5rem;

    --local-index: '1';
}

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
* {
    margin: 0;
    padding: 0;
    font: inherit;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

html,
body {
    height: 100%;
    background-color: var(--clr-background-default);
    color: var(--clr-default);
}

/* Set core body defaults */
body {
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture,
svg {
    max-width: 100%;
    display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

@media (max-width: 10000px) {
    html {
        font-size: 20px;
    }
}

@media (max-width: 1400px) {
    html {
        font-size: 18px;
    }
}

@media (max-width: 1200px) {
    html {
        font-size: 16px;
    }
}

@media (max-width: 1050px) {
    html {
        font-size: 14px;
    }

    html:has(.main.mobile) {
      font-size: 16px;
    }
}


#app {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    position: relative;
    width: 100%;
    padding-bottom: 1em;
}

#app:has(header.mobile) {
    overflow-x: hidden;
}

.main {
  padding-top: 4em;
  padding-left: 16em;
  padding-right: 2em;
}

.main.fullscreen {
  padding-top: 4em;
  padding-left: 2em;
  padding-right: 2em;
}

.main.mobile {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  transition: all 250ms;
  left: 0;
  position: relative;
}

.main.mobile.withmenu {
  left: 75%;
}

.login {
    position: fixed;
    top: -.5em;
    right: 1em;
    padding: 1em 1em 1em 0;
    z-index: 10;
    height: 2em;
    line-height: 2em;
}


.mobile.login {
    right: 0em;
}

.mobile .notconnected {
  color: white;
  top: -.7em;
right: -.7em;
position: relative;
}

@media only screen and (max-width: 350px) {
  .mobile .notconnected {
    font-size: var(--fs-300);
    display: block;
    width: 5em;
    line-height: 1;
    right: -1em;
    top: 0.5em;
    position: relative;
  }
}

.login img {
  display: inline;
  position: relative;
  top: .5em;
  width: 2em;
  height: 2em;
  margin-left: 1em;
}

.login a {
    color: var(--clr-blue);
    text-decoration: none;
    font-weight: 700;
}

nav {
    position: fixed;
    left: 1em;
    top: 1em;
    width: 14em;
    height: calc(100% - 2em);
    background: linear-gradient(180deg, #023570 0%, #017DAC 100%);
    border-radius: 1em;
    display: grid;
    padding: 1em;
    align-content: flex-start;
    gap: 0em;
}

nav.mobile {
  top: 0;
  left: 0;
  border-radius: 0;
  height: 100%;
  padding-top: 6em;
  width: 75%;
  z-index: 5;
}

header {
  position: fixed;
  display: grid;
  padding: 1em;
  align-content: flex-start;
  gap: 0em;
  background: none;
  border-radius: 0;
  background: linear-gradient(180deg, #023570 0%, #017DAC00 100%);
  top: 0;
  left: 0;
  width: 100%;
  height: 4em;
  z-index: 10;
}

header .burger {
  appearance: none;
  position: absolute;
  background-color: transparent;
  width: 2em;
  height: 2em;
  top: 1em;
  left: 1em;
  color: transparent;
  border: none;
}

header .burger span {
  display: block;
  width: 100%;
  height: .4em;
  background-color: var(--clr-white);
  position: absolute;
  transition: all 250ms;
  top: calc(50% - .2em);
  opacity: 1;

}

header .burger span:nth-child(1) {
  top: 0;
}

header .burger[aria-pressed="true"] span:nth-child(1) {
  top: 50%;
  opacity: 0;
}

header .burger[aria-pressed="true"] span:nth-child(2) {
  rotate: 45deg;
}

header .burger[aria-pressed="true"] span:nth-child(3) {
  rotate: -45deg;
}

header .burger span:nth-child(4) {
  bottom: 0;
  top: auto;
}

header .burger[aria-pressed="true"] span:nth-child(4) {
  top: 50%;
  opacity: 0;
}

nav .separator {
    width: 6em;
    height: 1px;
    background-color: var(--clr-blue-light);
    margin-inline: auto;
    margin-block: .5em 1em;
}

nav a {
    color: var(--clr-white);
    text-decoration: none;
    padding: .5em .5em .5em 2em;
    border-radius: .5em;
    text-align: left;
    background-image: url(@/assets/css/img/icon_lock.svg);
    background-repeat: no-repeat;
    background-size: 1em 1em;
    background-position: .5em center;
    margin-bottom: .5em;
}

nav a:not(.locked).learn {
  background-image: url(@/assets/css/img/icon_learn.svg);
}

nav a:not(.locked).firststeps {
  background-image: url(@/assets/css/img/icon_firststeps.svg);
}

nav a:not(.locked).modop {
  background-image: url(@/assets/css/img/icon_modop.svg);
}

nav a:not(.locked).event {
  background-image: url(@/assets/css/img/calendar.svg);
}

nav a:not(.locked).home {
  background-image: url(@/assets/css/img/icon_home.svg);
}

nav a.router-link-active {
    background-color: var(--clr-blue-light);
}

/*nav a.router-link-active+.separator {
    background-color: transparent;
}

nav .separator:has(+ a.router-link-active) {
    background-color: transparent;
}*/

nav .logo {
    width: 5em;
    margin-inline: auto;
    margin-bottom: 2em;
}

header .logo {
    width: auto;
    height: 2em;
    margin-inline: auto;
    margin-bottom: 2em;
}

@media only screen and (max-width: 600px) {

}


.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s;
}

.fade-enter-from {
  opacity:0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-from {
    opacity: 1;
}

.fade-leave-to {
    opacity: 0;
}


.fromup-enter-active,
.fromup-leave-active {
    transition: translate 250ms;
}

.fromup-enter-from {
  translate: 0 -5em 0 ;
}

.fromup-enter-to {
  translate: 0 0 0;
}

.fromup-leave-from {
    translate: 0 0 0;
}

.fromup-leave-to {
    translate: 0 -5em 0;
}


.fromleft-enter-active,
.fromleft-leave-active {
    transition: all 250ms;
}

.fromleft-enter-from {
  translate: -100% 0 0 ;
}

.fromleft-enter-to {
  translate: 0 0 0;
  opacity: 1;
}

.fromleft-leave-from {
    translate: 0 0 0;
}

.fromleft-leave-to {
    translate: -100% 0 0;
}



.fromright-enter-active,
.fromright-leave-active {
    transition: all 250ms;
}

.fromright-enter-from {
  translate: 100% 0 0 ;
}

.fromright-enter-to {
  translate: 0 0 0;
  opacity: 1;
}

.fromright-leave-from {
    translate: 0 0 0;
}

.fromright-leave-to {
    translate: 100% 0 0;
}


</style>