<template>
    <div class="videoplayer">
        <video ref="Video" crossorigin playsinline="playsinline" webkit-playsinline="webkit-playsinline" :poster="poster_url" @ended="videoend" @playing="videoplay" @click="togglePlayPause" @timeupdate="timeUpdate" @progress="calculateBufferRanges">
            <source :src="video_url" type="video/mp4" @error="catcherrorvideo = true" />
            <track v-if="srt_url" :label="$root.language" kind="subtitles" :srclang="$root.language" :src="srt_url" default />
        </video>

        <div class="controls__bar" :class="{ 'fixed': !videoBeingPlayed }">
            <div class="controls">
                <button class="controls__button" @click="togglePlayPause" :class="{
                                            'play': !videoBeingPlayed,
                                            'pause': videoBeingPlayed
                                        }">
                </button>
                <div class="progress-bar" ref="ProgressBar" @click="disable_seek?null:skipToPosition($event)">
                    <div class="progress-time" v-if="timeElapsed">{{ timeElapsed }}</div>
                    <span class="progress-back" :class="{ 'started': percentagePlayed !== 0 }" :style="{ width: percentagePlayed }"></span>
                    <div class="progress-ranges">
                        <span class="progress-range" v-for="range in bufferRanges" :key="range.index" :style="{ left: range.start, width: range.end }"></span>
                    </div>
                </div>
                <div class="time">{{ timeRemaining }}</div>
                <div class="full-screen" @click="enterFullScreen">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
        <div v-if="catcherrorvideo">Erreur Video : Désactivez votre ad-blocker</div>
    </div>
</template>
<script>
export default {
    name: 'VideoPlayer',
    props: {
        poster_url: String,
        video_url: String,
        srt_url: String,
        end_callback:{type:Function},
        start_callback:{type:Function},
        disable_seek: Boolean,
    },
    data() {
        return {
            catcherrorvideo: false,
            video: null,
            progressbar: null,
            videoBeingPlayed:false,
            timeElapsed: null,
            percentagePlayed: 0,
            timeRemaining: '00:00',
            bufferRanges: [],
        }
    },
    mounted() {
        this.video = this.$refs.Video;
        this.progressbar = this.$refs.ProgressBar;
        console.log("disable_seek",this.disable_seek)
    },
    methods: {

        videoplay() {
          if (this.start_callback) this.start_callback();
        },
        videoend() {

          this.videoBeingPlayed = false;

          if (this.end_callback) this.end_callback();
        },
        togglePlayPause() {
            (this.videoBeingPlayed) ? this.pauseVideo(): this.playVideo();
        },
        playVideo() {
            this.video.play();
            this.videoBeingPlayed = true;
        },
        pauseVideo() {
            this.video.pause();
            this.videoBeingPlayed = false;
        },
        getPercentageOffset(e) {
          return e.offsetX / this.progressbar.offsetWidth;
        },
        getCurrentTime(percentage) {
            return Math.floor(percentage * this.video.duration);
        },
        secondsToHumanReadable(num) {
            let hours = Math.floor(num / 3600);
            let minutes = Math.floor((num % 3600) / 60);
            let seconds = num % 60;

            // Add leading zero if needed
            hours = (hours < 10) ? '0' + hours : hours;
            minutes = (minutes < 10) ? '0' + minutes : minutes;
            seconds = (seconds < 10) ? '0' + seconds : seconds;

            // If hours > 0, return string with hours prepended
            if (hours > 0) {
                return hours + ':' + minutes + ':' + seconds;
            }

            return minutes + ':' + seconds;
        },
        showHoverTime(e) {
            let percentageOffset = this.getPercentageOffset(e);
            let timeInSeconds = this.getCurrentTime(percentageOffset);

            if (!isNaN(timeInSeconds)) {
                this.timeElapsed = this.secondsToHumanReadable(timeInSeconds);
            }
        },
        getPercentagePlayed() {
            this.percentagePlayed = Math.floor((100 / this.video?.duration) * this.video?.currentTime);
            return this.percentagePlayed + '%'
        },
        timeUpdate() {
            let timeRemainingInSeconds = this.getRemainingTime();
            this.timeRemaining = this.secondsToHumanReadable(timeRemainingInSeconds);
            this.percentagePlayed = this.getPercentagePlayed();
        },
        getRemainingTime() {
            return Math.round(this.video?.duration - this.video?.currentTime);
        },
        skipToPosition(e) {
            console.log(e)
            let percentageOffset = this.getPercentageOffset(e);
            this.video.currentTime = this.getCurrentTime(percentageOffset);
        },
        calculateBufferRanges() {

            // Reset ranges every time this is called
            this.bufferRanges = [];

            // Loop through all ranges to calculate start and end times
            for (let i = 0, l = this.video.buffered.length; i < l; i++) {
                const totalDuration = this.video.duration;
                const startTime = Math.floor((100 / totalDuration) * this.video.buffered.start(i));
                const endTime = Math.floor((100 / totalDuration) * this.video.buffered.end(i));
                this.bufferRanges.push({
                    start: startTime + '%',
                    end: endTime + '%'
                });
            }
        },
        enterFullScreen() {
            if (this.video.requestFullscreen) {
                this.video.requestFullscreen();
            } else if (this.video.webkitRequestFullScreen) {
                this.video.webkitRequestFullScreen();
            } else if (this.video.mozRequestFullScreen) {
                this.video.mozRequestFullScreen();
            } else if (this.video.msRequestFullscreen) {
                this.video.msRequestFullscreen();
            } else if (this.video.webkitEnterFullscreen) {
                this.video.webkitEnterFullscreen();
            }
        },
    },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
video {
    display: block;
    margin-inline: auto;
    width: min(100%, 40em);
    aspect-ratio: 16/9;
    border-radius: 1em;
}

.mobile video {
    /*width: calc(100% - 2em);*/
}

video[poster] {
    object-fit: cover;
}

    .videoplayer {
        position: relative;
        display: block;
        width: min(40rem, 100%);
        margin-inline: auto;
        min-height: 10em;
    }

    .videoplayer:hover .controls {
        opacity: 1;
        visibility: visible;
    }
    .videoplayer:focus {
        outline: none;
    }
    .videoplayer video {
        display: block;
        width: 100%;
    }
    /*.VJS_video-spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -30px 0 0 -30px;
        border-radius: 5px;
        background: rgba(0,0,0,0.6);
        padding: 14px;
        z-index: 99;
    }
    .VJS_video-spinner__inner,
    .VJS_video-spinner__inner:after {
        width: 20px;
        height: 20px;
        border-radius: 50%;
    }
    .VJS_video-spinner__inner {
        border: 16px solid rgba(255, 255, 255,.4);
        border-left: 16px solid #ffffff;
        animation: viewJsSpinner 1.1s infinite linear;
    }*/


    .videoplayer .controls {
        position: absolute;
        left: 1em;
        bottom: 1em;
        right: 1em;
        padding: .25em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .videoplayer .controls .controls__button {
        flex: 0 0 4em;
        display: block;
        position: relative;
        height: 4em;
        /*margin-right: 2em;*/
        text-align: center;
        /*background-color: rgba(0,0,0,.7);*/
        background-image: linear-gradient(180deg, #023570 0%, #017DAC 100%);
        border-radius: 50%;
        outline: solid 4px #FFFFFF50;
        border:none;
    }
    .videoplayer .controls .controls__button:hover {
        cursor: pointer;
    }
    .videoplayer .controls .controls__button:focus {
        /*outline: none;*/
    }
    .videoplayer .controls .controls__button:before{
        content: '';
        position: absolute;
    }
    .videoplayer .controls .controls__button.play:before {
        left: 50%;
        top: 50%;
        margin: -1em 0 0 -.5em;
        width: 0;
        height: 0;
        border-top: 1em solid transparent;
        border-bottom: 1em solid transparent;
        border-left: 1.5em solid white;
    }
    .videoplayer .controls .controls__button.pause:before{
        height: 2em;
        width: 1.5em;
        background-image: linear-gradient(to left, white, white 30%, transparent 31%, transparent 69%,white 70%, white 100%);
        top: 1em;
    }
    /*.videoplayer .controls .controls__button.pause:before {
        left: calc(50% + .2em);
    }*/
    .videoplayer .controls .controls__button.pause:before {
        left: calc(50% - .7em);
    }
    .controls__bar {
        display: flex;
        position: absolute;
        width: 100%;
        flex-grow: 1;
        align-items: center;
        background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.7) 100%);
        border-bottom-left-radius: 1em;
        border-bottom-right-radius: 1em;
        height: 6em;
        bottom: 0;
        
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
    }

    .videoplayer .controls__bar.fixed {
        opacity: 1;
        visibility: visible;
    }

    .progress-bar {
       flex: 1;
       margin: 0 1.5em;
       width: 100%;
       height: .5em;
       border-radius: .25em;
       background-color: rgba(255,255,255,.4);
       position: relative;
    }

    .progress-bar.active:hover {
        cursor: pointer;
    }
    .progress-bar:hover .progress-time {
        display: block;
    }
    .progress-time {
        display: none;
        position: absolute;
        top: -3.5em;
        left: 50%;
        background-color: rgba(255,255,255,.7);
        color: #fff;
        padding: .25em 1em;
        border-top-left-radius: .5em;
        border-top-right-radius: .5em;
    }
    .progress-back {
        position: absolute;
        height: .5em;
        top: 0;
        left: 0;
        width: 0;
        background-color: var(--clr-white);
        border-radius: .25em;
        z-index: 2;
    }
    .progress-ranges {
        position: absolute;
        height: .5em;
        top: 0;
        left: 0;
        right: 0;
        z-index: 0;
        overflow: hidden;
        pointer-events: none;
    }
    .progress-range {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        border-radius: .25em;
        background-color: grey;
        pointer-events: none;
    }
    .time {
        color: white;
        font-size: 1em;
        margin-right: 1em;
    }
    .VJS_controls__volume {
        width: 2.5em;
        height: 1.5em;
        position: relative;
    }
    .VJS_controls__volume-bar {
        width: .25em;
        position: absolute;
        bottom: 0;
        background-color: white;
        transition: height .25s ease;
    }
    .VJS_controls__volume-bar--active {
        background-color: #33ccff;
    }
    .VJS_controls__volume-bar:hover {
        cursor: pointer;
    }
    .VJS_controls__volume-bar:first-child  { left:0; height:5px; }
    .VJS_controls__volume-bar:nth-child(2) { left:5px; height:8px; }
    .VJS_controls__volume-bar:nth-child(3) { left:10px; height:12px; }
    .VJS_controls__volume-bar:nth-child(4) { left:15px; height:15px; }
    .VJS_controls__volume-bar:last-child   { left:20px; height:18px; }
    .VJS_controls__volume-bar:first-child:hover  { height:8px; }
    .VJS_controls__volume-bar:nth-child(2):hover { height:11px; }
    .VJS_controls__volume-bar:nth-child(3):hover { height:15px; }
    .VJS_controls__volume-bar:nth-child(4):hover { height:18px; }
    .VJS_controls__volume-bar:last-child:hover   { height:21px; }
    .full-screen {
        width: 3em;
        height: 2em;
        position: relative;
        transition: transform .2s ease;
        margin-right: 1em;
        --border-color: var(--clr-white);
        --border-width: 4px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: .3em;
    }

    .full-screen:hover {
        transform: scale(1.05);
        cursor: pointer;
    }
    .full-screen span {
        /*position: absolute;*/
        width: 1em;
        height: .8em;
        display: block;
        justify-self: center;
    }

    @media screen and (orientation:portrait) { 

        .mobile .controls {
          padding-inline: 0;
          bottom: 0;
        }

        .mobile .videoplayer .controls .controls__button {
            flex: 0 0 2em;
            background-image: none;
            background-color: transparent;
            outline: none;
        }

        .mobile .full-screen {
            --border-width: 2px;
            margin-right: 0;
        }

        /*.mobile video {
          /*border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;*/
          /*border-top:solid var(--clr-blue) 1px;
          border-left:solid var(--clr-blue) 1px;
          border-right:solid var(--clr-blue) 1px;*/
          /*width: calc(100% - 1em);
          border-radius: .5em;
        }

        .mobile .videoplayer {
            margin-bottom: 5em;
        }

        .mobile .controls__bar {
          /*border-bottom-left-radius: 0em;
          border-bottom-right-radius: 0em;*/
          /*width: calc(100% - 1em);
          left: .5em;
        }

        .mobile .videoplayer:after {
          content:'';
          position: absolute;
          top: -.5em;
          left: 0;
          width: 100%;
          height: calc(100% + 1em);
          border-bottom:solid var(--clr-blue) 1px;
          border-top:solid var(--clr-blue) 1px;
          border-left:solid var(--clr-blue) 1px;
          border-right:solid var(--clr-blue) 1px;
          z-index: 1;
          border-bottom-left-radius: 1em;
          border-bottom-right-radius: 1em;
          border-top-right-radius: 1em;
          border-top-left-radius: 1em;
        }

        .mobile .videoplayer .controls .controls__button {
            position: absolute;
            width: 4em;
            bottom: -5.2em;
            left: calc(50% - 2em);
            z-index: 2;
            transform-style: preserve-3d;
        }

        .mobile .videoplayer .controls .controls__button:after {
          content: '';
          position: absolute;
          width: calc(100% + 1em);
          height: calc(100% + 1em);
          top: -.5em;
          left: -.5em;
          border-radius: 50%;
          border-left: solid var(--clr-blue) 1px;
          border-bottom: solid var(--clr-blue) 1px;
          border-right: solid var(--clr-blue) 1px;
          border-top: solid transparent 1px;
          /*rotate: 45deg;*/
          /*background-color: var(--clr-background-default);
          transform: translateZ(-1px);
        }

        .mobile .full-screen {
            position: absolute;
            bottom: -4.4em;
            right: calc(25% - 3em);
            --border-color: var(--clr-blue);
            --border-width: 3px;
            background-color: var(--clr-white);
            width: 3em;
            height: 3em;
            border-radius: 50%;
            padding: .8em;
            gap: .3em;
            justify-content: center;
            align-content: center;
            align-items: center;
            margin-right: 0;
            z-index: 2;
            transform-style: preserve-3d;
        }

        .mobile .full-screen:after {
          content: '';
          position: absolute;
          width: calc(100% + 1em);
          height: calc(100% + 1em);
          top: -.5em;
          left: -.5em;
          border-radius: 50%;
          border-left: solid var(--clr-blue) 1px;
          border-bottom: solid var(--clr-blue) 1px;
          border-right: solid var(--clr-blue) 1px;
          border-top: solid transparent 1px;
          /*rotate: 45deg;*/

          /*background-color: var(--clr-background-default);
          transform: translateZ(-1px);
        }

        .mobile .full-screen span {
            width: .5em;
            height: .5em;
        }


        .mobile .videoplayer .controls {
            position: absolute;
            left: 0em;
            right: 0em;
        }*/
        
    }

    .full-screen span:first-child {
        top: 0;
        left: 0;
        border-left: var(--border-width) solid var(--border-color);
        border-top: var(--border-width) solid var(--border-color);
    }
    .full-screen span:nth-child(2) {
        top: 0;
        right: 0;
        border-top: var(--border-width) solid var(--border-color);
        border-right: var(--border-width) solid var(--border-color);
    }
    .full-screen span:nth-child(3) {
        bottom: 0;
        left: 0;
        border-left: var(--border-width) solid var(--border-color);
        border-bottom: var(--border-width) solid var(--border-color);
    }
    .full-screen span:last-child {
        bottom: 0;
        right: 0;
        border-bottom: var(--border-width) solid var(--border-color);
        border-right: var(--border-width) solid var(--border-color);
    }
    @keyframes viewJsSpinner {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }




</style>