<template>
    <iframe @click.stop id="iframelogin" :src="auth.connectUrl+'&lang='+ $root.language"></iframe>
</template>
<style scoped>
iframe {
    width: 100%;
    border: none;
    min-height: 570px;
}
</style>
<script>
import { kcAuthStore } from '@/stores/kcAuth'

export default {
    name: "KcLogin",
    data() {
        return {
            auth: kcAuthStore()
        }
    },
    // Mount vuejs
    mounted() {

        let $ = window.$;
        let that = this;


        this.$root.showMenu = false;

        this.$root.showNav = false;

        // Wait load iframe for show + post message here
        $('#iframelogin').on('load', function() {
            this.contentWindow.postMessage('imhere', '*');
        });

        // Init event message in parent
        window.removeEventListener('message', get);
        window.addEventListener('message', get, false);

        // Function get
        function get(e) {
            try {

                if (e.origin !== 'https://manager.korda.cloud' && e.origin !== 'https://manager-preprod.korda.cloud') return;

                if (!e.data) return;

                //console.log(e)
                // Get data
                let data = JSON.parse(e.data);

                // Prevent no token
                if (!data.token) return;

                that.auth.save(e.data);

                // Stop listen event
                window.removeEventListener('message', get);

                

                if (new URLSearchParams(window.location.search).get("redirect")) {
                    //window.location.href = new URLSearchParams(window.location.search).get("redirect");
                    that.$root.$router.push(new URLSearchParams(window.location.search).get("redirect"));
                } else {
                    //window.location.href = "/";


                        that.$root.$router.push('/'+that.$root.language);

                    
                }
            } catch (error) {
                //console.log(error)
            }
        }

        
        if (this.lng !== 'fr' && this.lng !== 'es') {
            this.$root.$router.push("/"+this.$root.language+"/"+this.lng);
        } else {
            this.$root.language = this.lng
        }
        
    },
    watch: {
        lng: function(newVal) {
            if (newVal !== 'fr' && newVal !== 'es') {
                this.$root.$router.push("/"+this.$root.language+"/"+newVal);
            } else {
                this.$root.language = this.lng
            }
        },
    },
    props:['lng'],
    methods: {

    }
}
</script>