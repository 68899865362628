import { defineStore } from 'pinia'

import { kcAuthStore } from '@/stores/kcAuth'

export const useLearnStore = defineStore('dataslearn', {
    state: () => {
        return { datas: {} }
    },
    // could also be defined as
    // state: () => ({ count: 0 })
    getters: {
        journey(state) {

            let result = [];
            if (state.datas.autodiagnv) {
                let l = state.datas.result_autodiagnv.find(x => x.choice === state.datas.autodiagnv.select).list
                for (let i in l) {
                    result.push(this.getParcours(l[i]))
                }
            }
            return result;


        },
        societyJourneyCodes(state) {

          let result = [];

          let auth = kcAuthStore();

          let g = auth.user.groups

          //g = [{name:"035"},{name:"default"},{name:"035"},{name:"A3824"},{name:"E00"}]

          for (let i in state.datas.result_autodiagnv) {

            result.push(g.find(x => state.datas.result_autodiagnv[i].for.indexOf(x.name) !== -1)?.name)

          }


          return result.filter(n=>n!==undefined);
        },
        isTester() {
          let auth = kcAuthStore();
          return auth.user.groups.find(x => x.name === "onetlearntest");
        }

    },
    actions: {
        async fetchUserTrackings() {
          console.log("----------------- fetchUserTrackings -------------------", this.datas.version)
            const auth = kcAuthStore();
            if (auth.isLoggedIn) {
                
                let serve = 'https://onet-learn.korda.cloud/'
                //serve = 'http://localhost:8081/'


                //let the_url = 'https://onet-learn.korda.cloud/v2/stream'
                let the_url = serve + 'v3/stream'

                let language = 'fr'

                if (auth.user.group.company_id == "zzfrlIEBfFR-AgGw-WH7") {
                    language = 'es'
                }



                if (auth.user.isTester) the_url = serve + 'v3/stream_test'


                console.log("-----------",the_url)

                let options = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ authapikey: auth.apikey, authlogin: auth.user.login, authtoken: auth.user.token, language: language })
                };

                console.log("oo", options, auth.user.isTester)




                if (!this.datas.version) {

                    let response = await fetch(the_url, options);
                    //let response = await fetch(the_url);

                    let user = await response.json();


                    console.log("pp", user)


                    this.datas = user;

                    this.datas.baseURL = "https://onet-learn.korda.cloud/";
                    this.setIconsURL();
                    let that = this;
                    
                    auth.getUserTracks(function(tracks) {


                        console.log("tracks", tracks)




                        auth.saveConf("learnlastupdate", new Date().toJSON());
                        auth.saveConf("learntracks", (auth.getConf("learntracks") || []).concat(tracks));

                        for (var i in auth.getConf("learntracks")) {
                            var tr = auth.getConf("learntracks")[i];
                            switch (tr.type) {
                                case "plug_state":
                                    if (that.getPlug(tr.key)) {
                                        that.getPlug(tr.key)[tr.value] = true;
                                    }
                                    break;
                                case "quiz_result":
                                    if (that.getPlug(tr.key)) {
                                        if (that.getPlug(tr.key).quiz) {
                                            that.getPlug(tr.key).quiz.passed = tr.value;
                                            that.getPlug(tr.key).quiz.validated = true;
                                        }
                                    }
                                    break;
                                case "quiz_for":
                                    if (that.getPlug(tr.key)) {
                                        if (that.getPlug(tr.key).quiz) {
                                            if (that.getPlug(tr.key).quiz[tr.value])
                                                that.getPlug(tr.key).quiz[tr.value].validated = true;
                                        }
                                    }
                                    break;
                                case "favorite_state":
                                    if (that.getPlug(tr.key)) {
                                        that.getPlug(tr.key).favorite = (tr.value == "true");
                                    }
                                    break;
                                case "quiz_state":
                                    if (that.getPlug(tr.key)) {
                                        if (that.getPlug(tr.key).quiz) {
                                            that.getPlug(tr.key).quiz[tr.value] = true;
                                        }
                                    }
                                    break;
                                case "parcours_state":
                                    if (that.getParcours(tr.key)) {
                                        that.getParcours(tr.key)[tr.value] = true;
                                    }
                                    break;
                                case "parcours_assess":
                                    if (that.getParcours(tr.key)) {
                                        that.getParcours(tr.key).score = Number(tr.value);
                                    }
                                    break;
                                case "current_plug":
                                    if (that.getParcours(tr.key)) {
                                        that.getParcours(tr.key).currentQ = tr.value;
                                    }
                                    break;
                                    /*case "autodiag3" :
                                      if (!this.datas.autodiag) {
                                        try {
                                          this.datas.autodiag = JSON.parse(tr.value);
                                        } catch (e) {
                                          console.debug(e);
                                        }
                                      }
                                    break;*/
                                case "autodiagnv":
                                    if (!that.datas.autodiagnv) {
                                        try {
                                            that.datas.autodiagnv = JSON.parse(tr.value);
                                        } catch (e) {
                                            console.debug(e);
                                        }
                                    }
                                    break;
                                case "certificat":
                                    if (tr.value === 'obtain') {
                                        console.log("obtain")
                                        if (that.getParcours(tr.key)) {
                                            that.getParcours(tr.key).obtain_date = tr.creation_date || new Date(tr.date);
                                        }
                                    }
                                    break;
                            }

                        }

                        that.datas.ready = true;


                    }, { date: auth.getConf("learnlastupdate"), fallbackroute: "/learn", types: 'parcours_state||parcours_assess||quiz_state||quiz_result||quiz_for||plug_state||autodiag||certificat||favorite_state||current_plug' })

                }



            } else {
                throw new Error('User must be authenticated')
            }
        },
        refresh() {
            console.log("refresh datas")
            this.datas = {};
            this.fetchUserTrackings();
        },
        setIconsURL() {
            if (!this.datas.icons_url) {
                this.datas.icons_url = {};
                for (var i in this.datas.icons) {
                    this.datas.icons_url[this.datas.icons[i]] = this.datas.baseURL + "export/icons/" + this.datas.icons[i] + ".svg";
                }
            }
        },
        getImageDensity() {
            var d = "hdpi";
            let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
            if (width >= 552) d = "xhdpi";
            if (width >= 828) d = "xxhdpi";
            return d;
        },
        getParcours(id) {

            if (!this.datas.parcours) return undefined;
            let p = this.datas.parcours.find(x => x.id === id);

            if (p && !p.loaded) {
                for (let i in p.plugs) {
                    p.plugs[i] = this.getPlug(p.plugs[i]);
                }
                p.background_url = this.datas.baseURL + "export/" + this.getImageDensity() + "_" + p.background + ".jpg";
                p.loaded = true;
            }

            return p;
        },
        getPlug(id) {
            if (!this.datas.plugs) return undefined;
            let plug = this.datas.plugs.find(x => x.id === id);
            if (plug && !plug.loaded) {

                if (plug.image) {
                    plug.image_url = this.datas.baseURL + "export/" + this.getImageDensity() + "_" + plug.image + ".jpg";
                }
                if (plug.function) {
                    plug.function[0].icon_url = this.datas.baseURL + "export/" + this.getImageDensity() + "_" + plug.function[0].icon + ".jpg";
                }
                for (let i in plug.steps) {
                    plug.steps[i].image_url = this.datas.baseURL + "export/" + this.getImageDensity() + "_" + plug.steps[i].icon + ".jpg";
                }

                for (let i in plug.types) {
                    plug.types[i].icon_url = this.datas.baseURL + "export/icons/" + plug.types[i].icon + ".svg";
                    for (let j in plug.types[i].steps) {
                        plug.types[i].steps[j].image_url = this.datas.baseURL + "export/" + this.getImageDensity() + "_" + plug.types[i].steps[j].icon + ".jpg";
                    }
                }

                for (let i in plug.bd) {
                    plug.bd[i].image_url = this.datas.baseURL + "export/" + this.getImageDensity() + "_" + plug.bd[i].icon + ".jpg";
                    plug.bd[i].sound_url = this.datas.baseURL + "sound/" + plug.bd[i].sound;
                }

                for (let i in plug.downloads) {
                    plug.downloads[i].icon_url = this.datas.baseURL + "export/icons/" + plug.downloads[i].icon + ".svg";
                    plug.downloads[i].dl_url = this.datas.baseURL + "downloads/" + plug.downloads[i].content;
                }

                for (let i in plug.texts) {
                    plug.texts[i].icon_url = this.datas.baseURL + "export/icons/" + plug.texts[i].icon + ".svg";
                }
                for (let i in plug.practices) {
                    plug.practices[i].image_url = this.datas.baseURL + "export/" + this.getImageDensity() + "_" + plug.practices[i].icon + ".jpg";
                }
                for (let i in plug.critere_qualite) {
                    plug.critere_qualite[i].image_url = this.datas.baseURL + "export/" + this.getImageDensity() + "_" + plug.critere_qualite[i].icon + ".jpg";
                }
                for (let i in plug.gammes) {
                    plug.gammes[i].image_url = this.datas.baseURL + "export/" + this.getImageDensity() + "_" + plug.gammes[i].icon + ".jpg";
                }

                if (plug.quiz) {

                    var unique = true;

                    for (let j in this.datas.parcours) {
                        if (plug.quiz[this.datas.parcours[j].id]) {
                            unique = false;
                            for (let k in plug.quiz[this.datas.parcours[j].id]) {
                                let q = plug.quiz[this.datas.parcours[j].id][k];
                                for (let i in q.answers) {
                                    q.answers[i].background_url = this.datas.baseURL + "export/" + this.getImageDensity() + "_" + q.answers[i].background + ".jpg";
                                    q.answers[i].sound_url = this.datas.baseURL + "sound/" + q.answers[i].sound;
                                }
                                q.question_sound_url = this.datas.baseURL + "sound/" + q.question_sound;
                                q.debrief_backgrounds_url = [];
                                q.debrief_sound_url = this.datas.baseURL + "sound/" + q.debrief_sound;
                                for (let i in q.debrief_backgrounds) {
                                    q.debrief_backgrounds_url.push(this.datas.baseURL + "export/" + this.getImageDensity() + "_" + q.debrief_backgrounds[i] + ".jpg");
                                }
                            }
                        }
                    }

                    if (plug.quiz.default) {
                        unique = false;
                        for (let k in plug.quiz.default) {
                            let q = plug.quiz.default[k];
                            for (let i in q.answers) {
                                q.answers[i].background_url = this.datas.baseURL + "export/" + this.getImageDensity() + "_" + q.answers[i].background + ".jpg";
                                q.answers[i].sound_url = this.datas.baseURL + "sound/" + q.answers[i].sound;
                            }
                            q.question_sound_url = this.datas.baseURL + "sound/" + q.question_sound;
                            q.debrief_backgrounds_url = [];
                            q.debrief_sound_url = this.datas.baseURL + "sound/" + q.debrief_sound;
                            for (let i in q.debrief_backgrounds) {
                                q.debrief_backgrounds_url.push(this.datas.baseURL + "export/" + this.getImageDensity() + "_" + q.debrief_backgrounds[i] + ".jpg");
                            }
                            if (q.debriefok) {
                                q.debriefok_backgrounds_url = [];
                                for (let i in q.debriefok_backgrounds) {
                                    q.debriefok_backgrounds_url.push(this.datas.baseURL + "export/" + this.getImageDensity() + "_" + q.debriefok_backgrounds[i] + ".jpg");
                                }
                                q.debriefko_backgrounds_url = [];
                                for (let i in q.debriefko_backgrounds) {
                                    q.debriefko_backgrounds_url.push(this.datas.baseURL + "export/" + this.getImageDensity() + "_" + q.debriefko_backgrounds[i] + ".jpg");
                                }
                            }
                        }
                    }


                    if (unique) {
                        plug.quiz.default = {};

                        for (let i in plug.quiz.answers) {
                            plug.quiz.answers[i].background_url = this.datas.baseURL + "export/" + this.getImageDensity() + "_" + plug.quiz.answers[i].background + ".jpg";
                            plug.quiz.answers[i].sound_url = this.datas.baseURL + "sound/" + plug.quiz.answers[i].sound;
                        }
                        plug.quiz.question_sound_url = this.datas.baseURL + "sound/" + plug.quiz.question_sound;
                        plug.quiz.debrief_backgrounds_url = [];
                        plug.quiz.debrief_sound_url = this.datas.baseURL + "sound/" + plug.quiz.debrief_sound;
                        for (let i in plug.quiz.debrief_backgrounds) {
                            plug.quiz.debrief_backgrounds_url.push(this.datas.baseURL + "export/" + this.getImageDensity() + "_" + plug.quiz.debrief_backgrounds[i] + ".jpg");
                        }

                        plug.quiz.default = {};
                        plug.quiz.default['1'] = {}
                        plug.quiz.default['1'].answers = plug.quiz.answers;
                        plug.quiz.default['1'].debrief = plug.quiz.debrief;
                        plug.quiz.default['1'].debrief_backgrounds = plug.quiz.debrief_backgrounds;
                        plug.quiz.default['1'].debrief_backgrounds_url = plug.quiz.debrief_backgrounds_url;
                        plug.quiz.default['1'].question = plug.quiz.question;
                        plug.quiz.default['1'].question_sound = plug.quiz.question_sound;
                        plug.quiz.default['1'].question_sound_url = plug.quiz.question_sound_url;
                        plug.quiz.default['1'].debrief_sound_url = plug.quiz.debrief_sound_url;
                        plug.quiz.default['1'].type = '';

                    }

                }



                plug.orderpart = [];

                if (plug.video_url) {
                    plug.orderpart.push("video");
                }
                plug.orderpart.push("card");

                if (plug.quiz) {
                    plug.orderpart.push("quiz");
                }

                plug.loaded = true;

            }
            return plug;
        },
        startParcours(parcours) {
            if (!parcours.started) {
                let auth = kcAuthStore()
                parcours.started = true;
                auth.setTrack('parcours_state', parcours.id, 'started');
            }
        },
        completeParcours(parcours) {
            if (!parcours.complete) {
                let auth = kcAuthStore();

                parcours.complete = true;
                auth.setTrack('parcours_state', parcours.id, 'complete');

                parcours.score = Math.round((parcours.plugs.filter((obj) => obj.quiz?.passed).length / parcours.plugs.filter((obj) => obj.quiz).length) * 100);
                auth.setTrack('parcours_assess', parcours.id, parcours.score);

                console.log("complete journey")
                if (!parcours.obtain_date) {
                    parcours.obtain_date = new Date().toISOString()
                    auth.setTrack('certificat', parcours.id, 'obtain', true);
                }

            }
        },
        setCurrentPlug(parcours, val) {
            let auth = kcAuthStore();
            parcours.currentQ = val;
            auth.setTrack('current_plug', parcours.id, val, true);
        },
        validQuiz(plug, parcoursID) {
            let auth = kcAuthStore();
            auth.setTrack('quiz_state', plug.id, 'complete', true);
            auth.setTrack('quiz_result', plug.id, plug.quiz.passed);
            if (parcoursID) {
                auth.setTrack('quiz_for', plug.id, parcoursID);
            }
        },


        startVideo (plug) {
          if (!plug.video_started) {
            let auth = kcAuthStore();
            plug.video_started = true;
            auth.setTrack('plug_state', plug.id, 'video_started');
          }
        },

        completeVideo (plug) {
          if (!plug.video_completed) {
            let auth = kcAuthStore();
            plug.video_completed = true;
            auth.setTrack('plug_state', plug.id, 'video_completed');
          }
        },

        favoritePlug (plug, val) {
          let auth = kcAuthStore();
          plug.favorite = val;
          auth.setTrack('favorite_state', plug.id, val, true);
        },

        viewPlug (plug) {
          if (!plug.viewed) {
            let auth = kcAuthStore();
            plug.viewed = true;
            auth.setTrack('plug_state', plug.id, 'viewed', true);
          }
        },

        completePlug (plug) {
          if (!plug.completed) {
            let auth = kcAuthStore();
            plug.completed = true;
            auth.setTrack('plug_state', plug.id, 'completed', true);
          }
        },

        settimePlug (plug, val) {
          let auth = kcAuthStore();
          auth.setTrack('plug_session_time', plug.id, val);
        },

        validAutodiag (content) {
          let auth = kcAuthStore();
          this.datas.autodiagnv = content;
          auth.setTrack('autodiagnv', 'valid', JSON.stringify(content), true);
        },
    },
})